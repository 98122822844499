<template>
  <body>
    <div class="absolute max-w-screen-2xl">
      <img src="../assets/bgtop.svg" alt="" class="w-screen absolute" />
      <div
        class="relative hidden md:flex justify-end items-center w-screen space-y-24"
      >
        <div
          class="absolute border-t border-b border-l border-blue-400 rounded-tl-full rounded-bl-full w-12 h-24 animate-pulse"
        ></div>
        <div
          class="absolute border-t border-b border-l border-blue-400 rounded-tl-full rounded-bl-full w-8 h-16 animate-pulse"
        ></div>
      </div>
    </div>
    <div
      class="relative md:w-auto mx-auto flex items-stretch justify-between flex-col md:flex-row col-end-1"
    >
      <div
        class="px-6 md:px-0 w-full lg:w-8/12 align-middle flex flex-col justify-center content-center"
      >
        <!-- <button v-on:click="test">TEST v-on</button> -->

        <h1
          class="md:pl-32 text-3xl md:text-3xl lg:text-4xl xl:text-5xl bt-fontonly font-medium text-dark-blue mb-2 text-left xl:text-left md:mt-0 mt-4 py-4"
        >
          Find the right mentor to
          <br />
          <!-- <span>
            <span class="text-transparent text-yellow-500 animate-typewriter"
              >Nail the job interview</span
            >
          </span> -->
          <span>
            <span
              class="typewriter text-yellow-500 text-3xl md:text-3xl lg:text-4xl xl:text-5xl bt-fontonly font-medium"
              ref="typewriter"
            >
              <span v-if="index === 0"></span>
              <span v-if="index === 1"></span>
              <span v-if="index === 2"></span>
              <span v-if="index === 3"></span>
              <span v-if="index === 4"></span>
              <span
                class="cursor"
                :style="{ left: cursorPosition + 'px' }"
              ></span>
            </span>
          </span>
        </h1>
        <div
          class="relative hidden md:flex justify-start items-center space-y-24 animate-pulse"
        >
          <div
            class="absolute border-t border-b border-r border-blue-400 rounded-tr-full rounded-br-full w-12 h-24"
          ></div>
          <div
            class="absolute border-t border-b border-r border-blue-400 rounded-tr-full rounded-br-full w-8 h-16"
          ></div>
        </div>

        <div class="absolute my-4 h-24 w-24"></div>

        <form @submit.prevent="searchMentor">
          <div class="flex justify-left">
            <div class="relative w-full md:w-10/12 md:pl-32">
              <input
                type="search"
                placeholder="Search a mentor, industry, country or company"
                ref="search"
                :maxlength="maxChar"
                :minlength="minChar"
                v-model="search"
                id='location-search"'
                class="border-2 border-custom-blue md:border-0 md:border-white shadow-xl block p-2.5 px-8 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-full"
                required=""
              />

              <button
                type="submit"
                class="absolute top-0 right-0 py-2.5 px-4 h-12 text-sm font-medium text-white rounded-r-full hover:bg-blue-500 bg-custom-blue duration-300 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                <svg
                  aria-hidden="true"
                  class="w-5 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </form>
        <div class="flex justify-start pt-4 lg:pt-8 md:pl-32">
          <TagsNew :searchTags="this.searchTags" />
        </div>
      </div>
      <!-- hidden md:visible lg:visible xl:visible -->
      <div
        class="w-11/12 md:w-full lg:w-6/12 xl:w-7/12 md:mb-14 xl:mb-0 relative flex items-center justify-center"
      >
        <img src="../assets/interview_new.svg" alt="" class="" />
      </div>
    </div>
  </body>
</template>

<script>
// import axios from "axios";
import TagsNew from "./TagsNew.vue";
import "../assets/main.css";
export default {
  name: "Search",

  data() {
    return {
      maxChar: 50,
      getMentorDetails: false,
      mentorDatas: [],
      search: "",
      searchTags: ["Azerbaijan", "Big4", "Software", "Finance"],

      index: 0,
      cursorPosition: 0,

      categories: [
        { id: 1, name: "Build/develop my career" },
        { id: 2, name: "Change my track" },
        { id: 3, name: "Study" },
        { id: 4, name: "Find jobs" },
      ],
      subcategories: [],
      selectedCategory: "",
      selectedSubcategory: "",
    };
  },

  components: {
    TagsNew,
  },
  mounted() {
    this.startTyping();
  },
  methods: {
    updateSubcategories() {
      if (this.selectedCategory) {
        // Perform an API request to retrieve subcategories based on the selected category
        // For this example, we'll just use hardcoded data
        switch (this.selectedCategory.id) {
          case 1:
            this.subcategories = [
              { id: 1, name: "Subcategory 1.1" },
              { id: 2, name: "Subcategory 1.2" },
              { id: 3, name: "Subcategory 1.3" },
            ];
            break;
          case 2:
            this.subcategories = [
              { id: 4, name: "Subcategory 2.1" },
              { id: 5, name: "Subcategory 2.2" },
              { id: 6, name: "Subcategory 2.3" },
            ];
            break;
          case 3:
            this.subcategories = [
              { id: 7, name: "Subcategory 3.1" },
              { id: 8, name: "Subcategory 3.2" },
              { id: 9, name: "Subcategory 3.3" },
            ];
            break;
          case 4:
            this.subcategories = [
              { id: 10, name: "Subcategory 4.1" },
              { id: 11, name: "Subcategory 4.2" },
              { id: 12, name: "Subcategory 4.3" },
            ];
            break;
        }
      } else {
        this.subcategories = [];
      }
      this.selectedSubcategory = null;
    },
    searchTest() {
      // Perform a search using the selected category and subcategory
      // For this example, we'll just log the selected values
      console.log(`Selected category: ${this.selectedCategory}`);
      console.log(`Selected subcategory: ${this.selectedSubcategory}`);
    },

    // const words = [
    //   "Crush the interview",
    //   "Find the right mentor",
    //   "Create your CV",
    // ];

    startTyping() {
      const words = [
        "Nail the job interview",
        "Boost your career",
        "Land a job abroad",
        "Switch your career",
        "Become MBA/PhD candidate",
      ];
      const interval = 50; // adjust the interval to change the typing speed
      const deleteInterval = 500; // adjust the delete interval to change the pause between words

      let i = 0;
      let isDeleting = false;
      let wordIndex = 0;

      setInterval(() => {
        const word = words[wordIndex];
        const currentText = isDeleting
          ? word.slice(0, --i)
          : word.slice(0, ++i);
        if (this.$refs.typewriter) {
          this.$refs.typewriter.textContent = currentText;

          this.$nextTick(() => {
            if (this.$refs.typewriter && this.$refs.typewriter.querySelector) {
              const lastSpan =
                this.$refs.typewriter.querySelector("span:last-child");
              this.cursorPosition = lastSpan ? lastSpan.offsetLeft : 0;
            }
            // const lastSpan =
            //   this.$refs.typewriter.querySelector("span:last-child");
            // this.cursorPosition = lastSpan ? lastSpan.offsetLeft : 0;
          });

          if (!isDeleting && currentText === word) {
            setTimeout(() => {
              isDeleting = true;
            }, deleteInterval);
          }

          if (isDeleting && currentText === "") {
            isDeleting = false;
            wordIndex = (wordIndex + 1) % words.length;
            this.cursorPosition = 0; // reset cursor position
          }
        }
      }, interval);
    },
    searchMentor() {
      console.log(this.search);
      this.$router.push(`/search/${this.search}/`);
      localStorage.setItem("query", this.search);
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Biotif";
  src: url("../assets/fonts/Biotif-Book.ttf") format("truetype"),
    url("../assets/fonts/Biotif-Medium.ttf") format("truetype"),
    url("../assets/fonts/Biotif-Regular.ttf") format("truetype");
}
.typewriter {
  /* font-size: 0.8em; */
  /* font-weight: bold; */
  /* font-family: Biotif; */
  border-right: 0.1em solid #000;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.typewriter span {
  display: inline-block;
}

.typewriter .cursor {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0.2em;
  background-color: #161e39;
  animation: blink-caret 0.75s step-end infinite;
}

@keyframes blink-caret {
  from,
  to {
    transform: translateX(0);
  }
  50% {
    transform: translateX(0.2em);
  }
}
</style>
