<template>
  <main class="wrapper">
    <div class="home" v-if="status === 'home'">
      <h2>Please wait</h2>
      <div class="start-call-container">
        <p v-if="roomError" class="error">Room could not be created</p>
        <!-- <p class="subtext">or</p> -->
        <!-- Daily room URL is entered here -->
        <!-- <input
          type="text"
          placeholder="Enter room URL..."
          v-model="roomUrl"
          pattern="^(https:\/\/)?[\w.-]+(\.(daily\.(co)))+[\/\/]+[\w.-]+$"
          @input="validateInput"
        /> -->
        <!-- button to submit URL and join call -->
        <!-- <button @click="joinRoom()">Join room</button> -->
      </div>
    </div>

    <div class="call-container" :class="{ hidden: status === 'home' }">
      <!-- The Daily Prebuilt iframe is embedded in the div below using the ref -->
      <div id="call" ref="callRef"></div>
      <!-- <headers
        :status="status"
        :roomUrl="roomUrl"
        :callFrame="callFrame"
        :runningLocally="runningLocally"
      /> -->
      <!-- Only show the control panel if a call is live -->
      <controlsmentee
        v-if="status === 'call'"
        :roomUrl="roomUrl"
        :callFrame="callFrame"
      />
    </div>
  </main>
</template>

<script>
import DailyIframe from "@daily-co/daily-js";
import controlsmentee from "../../components/ControlsMentee.vue";
// import Headers from "../../components/Headers.vue";
//import api from "./api.js";

export default {
  components: { controlsmentee },
  name: "Home",
  data() {
    return {
      roomUrl: "",
      status: "home",
      callFrame: null,
      validRoomURL: false,
      roomError: false,
      runningLocally: false,
      apiKey: process.env.VUE_APP_DAILY_API_KEY,
    };
  },

  mounted() {
    this.joinRoom();
  },

  methods: {
    // Daily callframe created and joined below
    joinRoom() {
      const apiKey = this.apiKey;
      const roomUrl = localStorage.getItem("roomURL");
      console.log(roomUrl);
      console.log(apiKey);
      const logEvent = (ev) => console.log(ev);
      const goToLobby = () => (this.status = "lobby");
      const goToCall = () => (this.status = "call");
      const leaveCall = () => {
        if (this.callFrame) {
          this.status = "home";
          this.callFrame.destroy();
          this.$router.push(
            "/ratementorpage/" + localStorage.getItem("mentorID")
          );
        }
      };
      // DailyIframe container element
      const callWrapper = this.$refs.callRef;
      const callFrame = DailyIframe.createFrame(callWrapper, {
        iframeStyle: {
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          border: "none",
          zIndex: "9999",
        },

        showLeaveButton: true,
      });
      this.callFrame = callFrame;

      callFrame
        .on("loaded", logEvent)
        .on("started-camera", logEvent)
        .on("camera-error", logEvent)
        .on("joining-meeting", goToLobby)
        .on("joined-meeting", goToCall)
        .on("left-meeting", leaveCall);

      // callFrame.join({ url });

      callFrame.join({ url: roomUrl, token: apiKey });
    },

    submitJoinRoom() {
      // console.log()
      console.log(localStorage.getItem("roomURL"));
      this.joinRoom();
    },
    validateInput(e) {
      this.validRoomURL = !!this.roomUrl && e.target.checkValidity();
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: var(--grey-lightest);
  height: 100%;
  display: flex;
  align-items: center;
}
.home {
  flex: 1;
}
.error {
  color: var(--red-dark);
}
.call-container {
  display: flex;
  max-width: 100%;
  margin: auto;
  flex: 1;
  flex-wrap: wrap;
}
.call-container.hidden {
  flex: 0;
}
#call {
  flex: 1;
  margin-top: 24px;
  /* display: flex;
  flex-direction: row; */
}
.start-call-container {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin: auto;
}
.start-call-container .subtext {
  margin: 8px 0 0;
}
.start-call-container input {
  margin-top: 8px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid var(--grey);
  padding: 0 16px;
}
.start-call-container button {
  color: var(--dark-blue);
  background: var(--teal);
  border: 1px solid transparent;
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
}
.start-call-container input[type="submit"] {
  color: var(--dark-blue);
  background: var(--teal);
  border: 1px solid transparent;
  padding: 6px 16px 8px;
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 16px;
}
button:disabled,
.start-call-container input[type="submit"]:disabled {
  cursor: not-allowed;
  background: var(--white);
  border: 1px solid var(--grey);
}
label {
  opacity: 0;
  font-size: 1px;
}
</style>
