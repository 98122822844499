<template>
  <div class="p-4 md:p-8">
    <div class="flex flex-col">
      <p class="bt-md text-left text-gray-800">Change my password</p>
      <div class="flex flex-col justify-center space-y-8 py-10">
        <div class="space-y-8 flex flex-col">
          <form action="" submit.prevent="updatePassword()"></form>
          <input
            type="password"
            class="border w-full lg:w-1/2 border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="Old password"
            v-model="oldPassword"
          />
          <input
            type="password"
            class="border w-full lg:w-1/2 border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="New password"
            v-model="newPassword"
          />
          <input
            type="password"
            class="border w-full lg:w-1/2 border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="New password"
            v-model="confirmPassword"
          />

          <!-- <input
            type="password"
            class="border w-full lg:w-1/2 border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="new"
            v-model="newPassword"
          /> -->
        </div>

        <div class="w-full lg:w-1/2">
          <!-- @click="updateCandidate(candDatas.userID)" -->
          <div
            class="flex justify-center items-center content-center align-middle"
          >
            <button
              @click="updatePassword()"
              class="py-1.5 bg-custom-blue rounded-full text-white px-3"
            >
              <p class="text-white bt-book">Update</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      candDatas: "",
      oldPassword: "",
      oldFromBackend: "",
      newPassword: "",
      confirmPassword: "",
      toastMessage: "",
      toastColor: "",
    };
  },

  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  beforeMount() {
    this.getCandData();
    this.getCandidateUserID();
  },

  methods: {
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },

    showToast() {
      this.toast.success("Password changed!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    //update password in firebase
    async updatePassword() {
      if (
        this.oldPassword === "" ||
        this.newPassword === "" ||
        this.confirmPassword === ""
      ) {
        this.showErrorToast("Please fill all fields");
      } else if (this.newPassword !== this.confirmPassword) {
        this.showErrorToast("Passwords do not match");
      } else {
        const user = firebase.auth().currentUser;
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email,
          this.oldPassword
        );

        user
          .reauthenticateWithCredential(credential)
          .then(() => {
            user
              .updatePassword(this.newPassword)
              .then(() => {
                console.log("Password updated successfully");
                this.oldPassword = "";
                this.newPassword = "";
                this.confirmPassword = "";
                this.showToast();
              })
              .catch((error) => {
                console.error(error);
                this.showErrorToast("Password update failed");
              });
          })
          .catch((error) => {
            console.error(error);
            this.showErrorToast("Password update failed");
          });
      }
    },
  },
};
</script>

<style></style>
