<template>
  <span v-if="showMentorFeedback == true">
    <seeMentorFeedback :mentorId="this.mentorId" />
  </span>
  <div v-if="showMentorFeedback == false" class="p-3">
    <div>
      <p class="bt-md text-left text-gray-800">Sessions</p>
    </div>
    <ul
      class="nav nav-tabs flex flex-row flex-wrap list-none border-b-0 pl-0 mb-0 md:mb-4 space-x-4"
      id="tabs-tab"
      role="tablist"
    >
      <li
        class="nav-item duration-300 ease-in-out hover:text-blue-500"
        role="presentation"
        v-bind:class="{
          'text-gray-900 border-b-2  border-gray-200': openTab !== 1,
          'text-custom-blue border-b-2 border-custom-blue': openTab === 1,
        }"
      >
        <a
          class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
          v-on:click="toggleTabs(1)"
        >
          <span class="pr-1.5">Upcoming</span>

          <span
            v-if="upcomingSessions > 0"
            class="text-xs px-1.5 text-white bg-red-600 rounded-full"
            >{{ upcomingSessions }}</span
          >
        </a>
      </li>
      <li
        class="nav-item duration-300 ease-in-out hover:text-blue-500"
        role="presentation"
        v-bind:class="{
          'text-gray-900 border-b-2  border-gray-200': openTab !== 2,
          'text-custom-blue border-b-2 border-custom-blue': openTab === 2,
        }"
      >
        <a
          class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
          v-on:click="toggleTabs(2)"
          >Past</a
        >
      </li>
      <li
        class="nav-item duration-300 ease-in-out hover:text-blue-500"
        role="presentation"
        v-bind:class="{
          'text-gray-900 border-b-2  border-gray-200': openTab !== 3,
          'text-custom-blue border-b-2 border-custom-blue': openTab === 3,
        }"
      >
        <a
          class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
          v-on:click="toggleTabs(3)"
          >All</a
        >
      </li>
    </ul>
    <div class="tab-content" id="tabs-tabContent">
      <div v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }">
        <!-- list of upcoming sessions -->
        <div
          v-if="noSessionsUpcoming == true"
          class="flex flex-col justify-center h-96"
        >
          <div
            class="flex flex-col justify-center align-middle content-center items-center"
          >
            <img
              src="../../assets/noSessionsNew.svg"
              class="justify-center align-middle content-center items-center z-50"
              alt=""
            />
            <img
              src="../../assets/noSessionsNew2.svg"
              class="justify-center align-middle content-center items-center -mt-48 transform hover:rotate-45 transition-transform duration-500 ease-in-out"
              alt=""
            />
            <p class="text-center bt-book py-8">No upcoming sessions</p>
          </div>
          <div class="flex justify-center">
            <router-link
              class="bg-custom-blue text-white px-2.5 py-2 font-bold text-xl rounded-xl"
              to="/mentors"
            >
              Find a mentor
            </router-link>
          </div>
        </div>
        <ul v-if="noSessionsUpcoming == false">
          <li
            class="flex py-2"
            v-for="candData in candDatas"
            :key="candData.userID"
            :currentMentorId="candData.mentorID"
          >
            <div
              class="w-full flex flex-row rounded-2xl py-2 md:px-6 bg-gray-50"
            >
              <div class="py-2">
                <img
                  :src="candData.mentorPhoto"
                  alt=""
                  class="h-12 w-12 rounded-full mr-2"
                />
              </div>
              <div class="w-full p-2 h-100">
                <div class="flex items-stretch justify-between">
                  <div>
                    <p class="bt-bold text-left">
                      {{ candData.sessionTopic }}
                    </p>
                  </div>
                  <div>
                    <p class="bt-bold text-custom-blue">
                      ${{ candData.sessionPrice }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between">
                  <div>
                    <p class="bt-placeholder text-left">
                      From {{ candData.mentorFirstName }}
                      {{ candData.mentorLastName }}
                    </p>
                  </div>
                  <div>
                    <p class="bt-placeholder">{{ candData.date }}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pt-4 sm:pt-8">
                  <div class="flex items-center">
                    <!-- border-2 border-custom-blue rounded-full -->
                    <button @click="seeYourRequest(candData.mentorID)" class="">
                      <p
                        class="bt-footer text-custom-blue md:underline bt-button text-left"
                      >
                        See your request
                      </p>
                    </button>
                  </div>
                  <div>
                    <button
                      v-if="candData.sessionLink != null"
                      @click="
                        getRoomData(candData.sessionID, candData.mentorID)
                      "
                      class="bg-custom-blue text-white px-2 py-1.5 rounded-xl"
                    >
                      Join Session
                    </button>
                    <span
                      class="flex flex-col justify-end align-middle content-end items-end"
                      v-if="candData.sessionLink == null"
                    >
                      <button
                        :disabled="candData.sessionLink == null"
                        class="bg-gray-500 text-white px-2 py-1.5 rounded-xl cursor-not-allowed"
                      >
                        Pending
                      </button>
                      <small class="text-custom-blue text-right">
                        Waiting for mentor confirmation
                      </small>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }">
      <div
        v-if="noSessionsPast == true"
        class="flex flex-col justify-center h-96"
      >
        <div
          class="flex flex-col justify-center align-middle content-center items-center"
        >
          <img
            src="../../assets/noSessionsNew.svg"
            class="justify-center align-middle content-center items-center z-50"
            alt=""
          />
          <img
            src="../../assets/noSessionsNew2.svg"
            class="justify-center align-middle content-center items-center -mt-48 transform hover:rotate-45 transition-transform duration-500 ease-in-out"
            alt=""
          />
          <p class="text-center bt-book py-8">No past sessions</p>
        </div>
        <div class="flex justify-center">
          <router-link
            class="bg-custom-blue text-white px-2.5 py-2 font-bold text-xl rounded-xl"
            to="/mentors"
          >
            Find a mentor
          </router-link>
        </div>
      </div>
      <ul v-if="noSessionsPast == false">
        <li
          class="flex p-2"
          v-for="pastCandData in pastCandDatas"
          :key="pastCandData.userID"
        >
          <div class="w-full flex flex-row rounded-2xl p-2 bg-gray-100">
            <div class="p-2">
              <img
                :src="pastCandData.mentorPhoto"
                alt=""
                class="h-12 w-12 rounded-full mx-2"
              />
            </div>
            <div class="w-full p-2 h-100">
              <div class="flex items-stretch justify-between">
                <div>
                  <p class="bt-bold text-left">
                    {{ pastCandData.sessionTopic }}
                  </p>
                </div>
                <div>
                  <p class="bt-bold text-custom-blue">
                    ${{ pastCandData.sessionPrice }}
                  </p>
                </div>
              </div>
              <div class="flex flex-row justify-between">
                <div>
                  <p>
                    {{ pastCandData.mentorFirstName }}
                    {{ pastCandData.mentorLastName }}
                  </p>
                </div>
                <div>
                  <p>{{ pastCandData.date }}</p>
                </div>
              </div>
              <div
                class="flex flex-row justify-between content-end align-bottom pt-8"
              >
                <div>
                  <button
                    @click="seeMentorFeedback(pastCandData.mentorID)"
                    class="bt-footer text-custom-blue underline"
                  >
                    <p>See mentor's feedback</p>
                  </button>
                </div>
                <div>
                  <button
                    class="bg-custom-blue px-3 py-1.5 text-white rounded-full"
                  >
                    <p>Rate mentor</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }">
      <div
        v-if="noSessionsAll == true"
        class="flex flex-col justify-center h-96"
      >
        <div
          class="flex flex-col justify-center align-middle content-center items-center"
        >
          <img
            src="../../assets/noSessionsNew.svg"
            class="justify-center align-middle content-center items-center z-50"
            alt=""
          />
          <img
            src="../../assets/noSessionsNew2.svg"
            class="justify-center align-middle content-center items-center -mt-48 transform hover:rotate-45 transition-transform duration-500 ease-in-out"
            alt=""
          />
          <p class="text-center bt-book py-8">No sessions</p>
        </div>
        <div class="flex justify-center">
          <router-link
            class="bg-custom-blue text-white px-2.5 py-2 font-bold text-xl rounded-xl"
            to="/mentors"
          >
            Find a mentor
          </router-link>
        </div>
      </div>
      <ul v-if="noSessionsAll == false">
        <li
          class="flex p-2"
          v-for="allCandData in allCandDatas"
          :key="allCandData.userID"
        >
          <div class="w-full flex flex-row rounded-2xl p-2 bg-gray-100">
            <div class="p-2">
              <img
                :src="allCandData.mentorPhoto"
                alt=""
                class="h-12 w-12 rounded-full mx-2"
              />
            </div>
            <div class="w-full p-2 h-100">
              <div class="flex items-stretch justify-between">
                <div>
                  <p class="bt-bold text-left">
                    {{ allCandData.sessionTopic }}
                  </p>
                </div>
                <div>
                  <p class="bt-bold text-custom-blue">
                    ${{ allCandData.sessionPrice }}
                  </p>
                </div>
              </div>
              <div class="flex flex-row justify-between">
                <div>
                  <p>
                    {{ allCandData.mentorFirstName }}
                    {{ allCandData.mentorLastName }}
                  </p>
                </div>
                <div>
                  <p>{{ allCandData.date }}</p>
                </div>
              </div>
              <div
                class="flex flex-row justify-between content-end align-bottom pt-8"
              >
                <div
                  class="flex align-middle justify-center items-center content-center"
                >
                  <p
                    v-if="allCandData.timeFlag == 'Upcoming'"
                    class="bt-footer text-custom-blue underline"
                  >
                    See your request
                  </p>
                  <p
                    v-if="allCandData.timeFlag == 'Past'"
                    class="bt-footer text-custom-blue underline"
                  >
                    See mentor's feedback
                  </p>
                </div>
                <div>
                  <button
                    @click="getRoomData(allCandData.sessionID)"
                    v-if="allCandData.timeFlag == 'Upcoming'"
                    class="bg-custom-blue px-3 py-1.5 text-white rounded-full"
                  >
                    <p>Join Meeting</p>
                  </button>
                  <button
                    v-if="allCandData.timeFlag == 'Past'"
                    class="bg-custom-blue px-3 py-1.5 text-white rounded-full"
                  >
                    <p>Rate mentor</p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import DailyIframe from "@daily-co/daily-js";
import axios from "axios";
import seeMentorFeedback from "./seeMentorFeedback.vue";
export default {
  data() {
    return {
      candDatas: "",
      pastCandDatas: "",
      allCandDatas: "",
      openTab: 1,
      upcomingSessions: 0,
      noSessionsUpcoming: false,
      noSessionsPast: false,
      noSessionsAll: false,
      showMentorFeedback: false,
    };
  },

  components: {
    seeMentorFeedback,
  },

  mounted() {
    this.getUpcomingCandidateData();
    this.getPastCandidateData();
    this.getAllCandidateData();
    setTimeout(() => {
      this.getUpcomingCandidateData();
      this.getPastCandidateData();
      this.getAllCandidateData();
    }, 5000);
  },

  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },

    async getCandidateUserID() {
      localStorage.getItem("userID")
        ? (this.userID = localStorage.getItem("userID"))
        : null;
      console.log(this.userID);
      this.getCandidateData(this.userID);
    },

    async rateMentor(mentorId) {
      //console.log(mentorId);
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/expert/profile/" +
            mentorId +
            "/"
        )
        .then((response) => {
          console.log(response);
          this.mentorDatas = response.data;
          console.log(this.currentMentorId);
        })
        .catch((error) => {
          console.log(error);
        });
      this.$router.push("/rateMentorPage/" + mentorId);
    },

    seeMentorFeedback(mentorId) {
      this.mentorId = mentorId;
      this.showMentorFeedback = true;
    },

    async createMeetingToken() {
      const url = "https://api.daily.co/v1/meeting-tokens";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.apiKey}`,
        },
        body: JSON.stringify({
          properties: {
            eject_at_room_exp: true,
          },
        }),
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        console.log(data);
        console.log(data.token);
        localStorage.setItem("token", data.token);
      } catch (error) {
        console.error("Error:", error);
      }
    },
    async getRoomData(sessionID, mentorID) {
      console.log(sessionID);
      console.log(mentorID);
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/get-room/" +
            sessionID +
            "/"
        )
        .then((response) => {
          console.log(response);
          this.roomURL = response.data.link;
          console.log(this.roomURL);
          //this.joinRoom(this.roomURL);
          localStorage.setItem("roomURL", this.roomURL);
          localStorage.setItem("mentorID", mentorID);
          console.log(localStorage.getItem("mentorID"));
          this.$router.push("/sessionMentee");
          // open url in new tab
          //window.open(this.roomURL, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getUpcomingCandidateData() {
      await axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/upcomingSessionsMentee/" +
            localStorage.getItem("userID") +
            "/"
        )
        .then((response) => {
          this.candDatas = response.data;
          console.log(this.candDatas);
          this.isLoading = false;

          // split date and time and convert date to dd/mm/yyyy
          this.candDatas.forEach((element) => {
            element.date = element.date.split("T")[0];
            element.date = element.date.split("-").reverse().join("/");
          });

          // the number of upcoming sessions
          this.upcomingSessions = this.candDatas.length;
          if (this.upcomingSessions == 0) {
            this.noSessionsUpcoming = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getPastCandidateData() {
      await axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/pastSessionsMentee/" +
            localStorage.getItem("userID") +
            "/"
        )
        .then((response) => {
          this.pastCandDatas = response.data;
          console.log(this.pastCandDatas);
          this.isLoading = false;
          // split date and time and convert date to dd/mm/yyyy and time to hh:mm
          this.pastCandDatas.forEach((element) => {
            element.date = element.date.split("T")[0];
          });
          if (this.pastCandDatas.length == 0) {
            this.noSessionsPast = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAllCandidateData() {
      await axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/getAllSessionMentee/" +
            localStorage.getItem("userID") +
            "/"
        )

        .then((response) => {
          this.allCandDatas = response.data;
          console.log(this.allCandDatas);

          this.isLoading = false;
          // split date and time and convert date to dd/mm/yyyy and time to hh:mm
          this.allCandDatas.forEach((element) => {
            element.date = element.date.split("T")[0];
          });
          if (this.allCandDatas.length == 0) {
            this.noSessionsAll = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    showUpcomingSessions() {},
  },
};
</script>

<style></style>
