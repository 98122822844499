import { createRouter, createWebHistory } from "vue-router";
import About from "../pages/About.vue";
import HowItWorks from "../pages/HowItWorks.vue";
import WelcomePage from "../pages/WelcomePage.vue";
import MentorPage from "../pages/MentorPage.vue";
import MentorDetails from "../pages/MentorDetails.vue";
import SignUpCandidate from "../pages/SignUpCandidate.vue";
import Login from "../pages/Login.vue";
import Search from "../pages/SearchMentor.vue";
import CandidateDashboard from "../pages/CandidateDashboard.vue";
import MentorCategories from "../pages/MentorCategories.vue";
import AboutMe from "../components/UserDashboard/aboutMe.vue";
import menteeSessions from "../components/UserDashboard/menteeSessions.vue";
import Payment from "../components/UserDashboard/Payment.vue";
import Security from "../components/UserDashboard/Security.vue";
import rateMentor from "../components/UserDashboard/rateMentor.vue";
import rateMentorPage from "../pages/UserDashboard/rateMentorPage.vue";
import MentorLogin from "../pages/MentorSide/mentorLogin.vue";
import MentorDashboardNav from "../pages/MentorSide/MentorDashboardNav.vue";
import MentorRegister from "../pages/MentorSide/MentorRegister.vue";
import mentorAboutMe from "../components/MentorDashboard/mentorAboutMe.vue";
import mentorSessions from "../components/MentorDashboard/mentorSessions.vue";
import mentorSecurity from "../components/MentorDashboard/mentorSecurity.vue";
import mentorPayment from "../components/MentorDashboard/mentorPayment.vue";
import mentorFeedback from "../pages/MentorSide/mentorFeedback.vue";
import mentorSeeRequest from "../pages/MentorSide/mentorSeeRequest.vue";
import seeMentorFeedback from "../components/UserDashboard/seeMentorFeedback.vue";
import notFoundPage from "../pages/notFoundPage.vue";
import PopupCalendar from "../components/MentorDashboard/PopupCalendar.vue";
import webinarPagePaid from "../pages/webinarPagePaid.vue";
import mbaClub from "../pages/mbaClub.vue";
import sessionMentor from "../pages/dailyJS/sessionMentor.vue";
import sessionMentee from "../pages/dailyJS/sessionMentee.vue";
import { getAuth } from "firebase/auth";
import paymentSuccess from "../pages/paymentSuccess.vue";
import paymentFailed from "../pages/paymentFailed.vue";
import cvScreening from "../pages/CVscreening.vue";

const routes = [
  {
    path: "/cvScreening",
    name: "cvScreening",
    component: cvScreening,
  },
  {
    path: "/sessionMentee",
    name: "sessionMentee",
    component: sessionMentee,
  },
  {
    path: "/payment-declined",
    name: "paymentFailed",
    component: paymentFailed,
  },
  {
    path: "/payment-successful",
    name: "paymentSuccess",
    component: paymentSuccess,
  },
  {
    path: "/session",
    name: "session",
    component: sessionMentor,
  },
  {
    path: "/MBAclub",
    name: "mbaClub",
    component: mbaClub,
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFoundPage",
    component: notFoundPage,
  },
  {
    path: "/mentorlogin",
    name: "mentorlogin",
    component: MentorLogin,
  },

  {
    path: "/MentorRegister",
    name: "MentorRegister",
    component: MentorRegister,
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/search/:search",
    name: "Search",
    component: Search,
  },

  {
    path: "/signup/",
    name: "MenteeRegister",
    component: SignUpCandidate,
  },

  {
    path: "/",
    name: "Welcome Page",
    component: WelcomePage,
  },

  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/howitworks",
    name: "HowItWorks",
    component: HowItWorks,
  },

  {
    path: "/mentors",
    name: "Mentor Page",
    component: MentorPage,
    props: true,
    meta: {
      description:
        "Get the support and guidance you need to succeed in your career with a professional help from our mentors.",
    },
  },
  {
    path: "/mentors/categories/:category",
    name: "Mentor Categories",
    component: MentorCategories,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/mentor/:id",
    name: "Mentor Details",
    component: MentorDetails,
    props: true,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/mentor/:str",
    name: "MentorDetails",
    component: MentorDetails,
    props: true,
    // meta: {
    //   requiresAuth: true,
    // },
  },

  {
    path: "/rateMentorPage/:id",
    name: "rateMentorPage",
    component: rateMentorPage,
  },

  {
    path: "/Dashboard",
    name: "CandidateDashboard",
    component: CandidateDashboard,
    // meta: {
    //   requiresAuth: true,
    // },
    children: [
      {
        path: "aboutme",
        name: "AboutMe",
        component: AboutMe,
      },

      {
        path: "sessions",
        name: "sessions",
        component: menteeSessions,
        //set rateMentor as a child of sessions
        children: [
          {
            path: "rateMentor/:id",
            name: "rateMentor",
            component: rateMentor,
          },
        ],
      },
      {
        path: "payment",
        name: "payment",
        component: Payment,
      },
      {
        path: "security",
        name: "security",
        component: Security,
      },
      {
        path: "seeMentorFeedback/:id",
        name: "seeMentorFeedback",
        component: seeMentorFeedback,
      },
    ],
  },

  {
    path: "/mentorFeedback/:id",
    name: "mentorFeedback",
    component: mentorFeedback,
  },

  {
    path: "/MentorDashboard",
    name: "MentorDashboard",
    component: MentorDashboardNav,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "aboutme",
        name: "mentorAboutMe",
        component: mentorAboutMe,
        children: [
          {
            path: "popupCalendar",
            name: "PopupCalendar",
            component: PopupCalendar,
          },
        ],
      },
      {
        path: "sessions",
        name: "mentorSessions",
        component: mentorSessions,
      },
      {
        path: "security",
        name: "mentorSecurity",
        component: mentorSecurity,
      },
      {
        path: "payment",
        name: "mentorPayment",
        component: mentorPayment,
      },
    ],
  },

  {
    path: "/mentorSeeRequest/:id",
    name: "mentorSeeRequest",
    component: mentorSeeRequest,
  },

  {
    path: "/webinar",
    name: "webinarPagePaid",
    component: webinarPagePaid,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  // const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  // const currentUser = firebase.auth().currentUser;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (getAuth().currentUser) {
      // sessionStorage.setItem("currentRoute", to.path);
      next();
    } else {
      localStorage.setItem("redirectPath", to.path);

      next("/login");
    }
  } else {
    next();
  }
});

export default router;
