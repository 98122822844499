<template>
  <body class="effra-light">
    <div
      class="pt-11 md:w-auto xl:w-auto mx-auto flex items-stretch justify-between md:flex-row col-end-1 px-6 md:px-32 md:space-x-8 pb-24"
    >
      <div class="">
        <div
          v-if="showPopupScreenWebinarInfo == true"
          style="
            overflow: hidden;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 9999;
          "
          class="z-50 absolute inset-0 flex items-center justify-center bg-opacity-90 bg-gray-800 backdrop-blur-md"
        >
          <popupScreenWebinarInfo @close="closeModal" />
        </div>
      </div>
      <div class="w-full pb-14 xl:mb-0 relative md:flex flex-col">
        <div class="text-left">
          <p class="">WEBINAR • Aysel Əlizadə</p>
          <p class="font-bold text-2xl py-4">*Subject name*</p>
        </div>
        <div class="text-left">
          <div class="flex justify-between md:max-w-md› pb-4">
            <div class="text-left flex flex-col">
              <p class="text-blue-900">Tarix</p>
              <p class="font-bold">9 sentyabr, 20:00 (Bakı)</p>
            </div>
            <div class="text-left flex flex-col">
              <p class="text-blue-900">Müddət</p>
              <p class="font-bold">60 dəqiqə</p>
            </div>
            <div class="text-left flex flex-col">
              <p class="text-blue-900">Qiymət</p>
              <p class="font-bold">40 AZN</p>
            </div>
          </div>
          <p class="effra-light text-gray-500">
            Hello, backpackers! Hirelamp-da vebinarlar silsiləsi geri dönür!
            <span class="text-custom-blue">Hirelamp</span>-da vebinarlar
            silsiləsi geri dönür!
          </p>
          <p class="pb-2 text-gray-500">
            Bu dəfə daha dərin, daha effektiv və daha nəticə yönümlü.
          </p>
          <p class="pb-2 text-gray-500">
            Qonağımız Niderlandın ən böyük şirkətlərindən biri olan ASML-da
            Scrum Master olaraq çalışan və öz təcrübəsini bölüşərək bir çox
            gəncə xaricdə iş və iş müsahibəsi əldə etməyə kömək edən Aysel
            Əlizadədir.
          </p>
          <p class="text-gray-700">Danışılacaq əsas mövzular</p>
          <ul class="pb-2 text-gray-500">
            <li>• Lorem Ipsum</li>
            <li>• Lorem Ipsum</li>
            <li>• Lorem Ipsum</li>
          </ul>
        </div>
        <div class="relative flex py-2.5 items-center px-3">
          <div class="flex-grow border-t border-gray-200"></div>
          <span class="flex-shrink mx-2 text-gray-300">•</span>
          <div class="flex-grow border-t border-gray-200"></div>
        </div>
        <div class="text-left">
          <div>
            <p class="font-bold text-xl pb-4">Aysel Əlizadə haqqında</p>
            <p class="text-gray-500">
              Aysel bir çoxumuzun xəyal etdiyi xaricdə iş arzusunu həyata
              keçirən professional Scrum Masterdir. Ölkənin ən iri banklarında
              ixtisası üzrə çalışdıqdan sonra Expo 2020 Dubai-dan iş təklifi
              alır. Daha sonra isə Niderlandda ASML şirkətində Scrum Master
              olaraq karyerasına davam edir. İndividual mentorluq, karyera
              inkişafı, düzgün və “işləyən” CV üzrə xeyli gəncə öz arzusunu
              həyata keçirməyə kömək edib. Bu arada, bu seminarda iştirak
              edənlər Aysellə 1 saatlıq individual sessiya üçün 20%-lik endirim
              promokodu əldə edəcək.
            </p>
          </div>
        </div>
      </div>
      <div class="w-full md:w-8/12 align-middle md:flex flex-col items-center">
        <img src="../assets/webinar4.jpg" alt="" class="w-full rounded-t-xl" />
        <div
          class="shadow-xl rounded-b-2xl rounded-tl-2xl w-full px-4 bg-white"
        >
          <p class="py-6">İştirak üçün formu doldurun</p>
          <div class="flex flex-col pb-4">
            <form action="" @submit.prevent="sendForm()">
              <div class="pb-4">
                <div class="relative">
                  <input
                    v-model="fullName"
                    type="text"
                    class="block border-1 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                    placeholder="Ad və Soyad"
                    required
                  />
                </div>

                <div class="py-2"></div>
                <div class="relative">
                  <input
                    v-model="email"
                    type="email"
                    class="block border-1 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                    placeholder="Email"
                    required
                  />
                </div>

                <div class="py-2"></div>
                <div class="relative pb-4">
                  <input
                    v-model="interests"
                    type="text"
                    class="block border-1 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                    placeholder="Maraqlandığınız mövzular"
                    required
                  />
                </div>

                <button
                  type="submit"
                  class="h-11 w-full rounded-full text-gray-50 bg-black hover:shadow-lg duration-300 px-2 py-1.5"
                  :disabled="!fullName || !email || !interests"
                >
                  <p>Ödə</p>

                  <!-- <div
                    v-if="loading"
                    class="flex items-center justify-center rounded-lg text-custom-blue"
                  >
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div> -->
                </button>
              </div>
            </form>
            <button
              type="submit"
              class="h-11 w-full rounded-full text-gray-50 bg-black hover:shadow-lg duration-300 px-2 py-1.5"
              @click="jaja()"
            >
              <p>Nəğd ödəmək</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </body>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";
import Footer from "../components/Footer.vue";
import popupScreenWebinarInfo from "../components/popupScreenWebinarInfo.vue";

export default {
  name: "Webinar",
  data() {
    return {
      toastMessage: "",
      toastColor: "",
      fullName: "",
      email: "",
      interests: "",
      loading: false,
      showPopupScreenWebinarInfo: false,
    };
  },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },

  components: {
    Footer,
    popupScreenWebinarInfo,
  },

  methods: {
    closeModal() {
      this.showPopupScreenWebinarInfo = false;
    },
    jaja() {
      console.log("hi");
      this.showPopupScreenWebinarInfo = true;
      console.log(this.showPopupScreenWebinarInfo);
    },

    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },

    showToast() {
      this.toast.success("Successfully registered!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    async sendPaymentDetails() {
      console.log(this.$root.uid);

      //total price is in AZN
      this.total = 1;
      this.aznTotal = this.total * 1.7025;

      // make this.aznTotal 2 decimal places
      this.aznTotal = this.aznTotal.toFixed(2);
      console.log(this.aznTotal);

      const formData = new FormData();
      formData.append("fullName", this.fullName);
      formData.append("email", this.email);
      formData.append("interests", this.interests);
      formData.append("mentorID", "f3a12fa3-cc40-492d-98c6-475fd38494d9");
      formData.append("menteeID", this.$root.uid);
      formData.append("amount", this.aznTotal);

      axios
        .post(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/login/payment/",
          formData
        )
        .then((response) => {
          console.log(response);
          this.paymentLink = response.data.res_checkout.url;
          window.location.href = this.paymentLink;
          console.log(this.paymentLink);

          // const purchaseData = {
          //   event: "purchase",
          //   ecommerce: {
          //     purchase: {
          //       actionField: {
          //         id: response.data.purchaseId, // Replace with the purchase ID from the response
          //         revenue: this.aznTotal, // Replace with the purchase total
          //         currency: "AZN", // Replace with the currency code
          //       },
          //       products: [
          //         {
          //           name: "Webinar", // Replace with the product name
          //           id: this.$root.uid, // Replace with the product ID
          //           price: this.aznTotal, // Replace with the product price
          //           quantity: 1, // Replace with the product quantity
          //         },
          //       ],
          //     },
          //   },
          // };
          // console.log(purchaseData);
          // trackEvent(purchaseData);
        });
    },

    sendForm() {
      if (this.fullName == "" && this.email == "" && this.interests == "") {
        this.showErrorToast("Please fill all the fields");
      } else {
        axios
          .post(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/webinars/register/",
            {
              webinarID: "f9096cda-8d28-4631-bb2f-afc78ca6ef64",
              fullName: this.fullName,
              email: this.email,
              interests: this.interests,
            }
          )
          .then((response) => {
            console.log(response);
            console.log(response.data.url);

            this.paymentLink = response.data.url;
            window.location.href = this.paymentLink;
            console.log(this.paymentLink);
            //this.showToast();
            // this.$router.push("/mentors");
          })
          .catch((error) => {
            this.showErrorToast(error.message);
          });
      }
    },
  },
};
</script>

<style></style>
