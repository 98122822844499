<template>
  <div class="overflow-hidden bg-white w-671 p-2 md:p-9 pt-4 rounded-xl">
    <div class="">
      <div class="flex justify-between">
        <button
          v-if="showContact"
          type="button"
          class="font-bold"
          @click="showContact = false"
        >
          <img src="../assets/back.png" class="w-6" alt="Back button" />
        </button>
        <button
          type="button"
          class="close font-bold"
          @click="$emit('close') && scrollTrue()"
        >
          <img src="../assets/closeButton.svg" alt="Close button" />
        </button>
      </div>
      <span v-if="showCalendar == true">
        <p class="font-black text-2xl flex justify-center">
          Choose the date and time
        </p>
        <p class="pb-9">
          Provided time slots are in
          <span class="font-bold">{{ this.timezone }} </span> timezone
        </p>
      </span>
    </div>

    <div
      class="w-full md:w-full flex flex-col lg:flex-row justify-center md:space-x-8"
    >
      <span v-if="showPayment == true">
        <div class="w-full mx-auto justify-center flex md:px-24 bt-fontonly">
          <div class="flex justify-between w-full h-full">
            <div class="flex flex-col">
              <div class="px-2">
                <div class="">
                  <p class="text-2xl font-bold pb-8">Your order</p>
                  <div class="">
                    <div
                      class="w-content md:w-96 flex justify-between border-2 p-3 rounded-xl"
                    >
                      <!-- <div class="flex space-x-12"> -->
                      <div class="flex justify-center align-middle">
                        <img
                          :src="this.mentorData.profileImg"
                          alt=""
                          class="rounded-full w-16 h-16"
                        />
                      </div>
                      <div class="flex flex-col text-left w-7/12 md:w-8/12">
                        <p class="font-bold">
                          {{ this.mentorFirstName + " " + this.mentorLastName }}
                        </p>
                        <p class="truncate">{{ this.message }}</p>
                        <!-- <p class="text-gray-500">27 May 2023 • 13:00-14:00</p> -->
                        <p class="text-gray-500">{{ this.testDate }}</p>
                      </div>
                      <!-- </div> -->
                      <div class="flex flex-col content-end">
                        <p class="font-bold text-custom-blue">
                          $ {{ this.mentorData.price }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="py-4">
                  <span class="flex">
                    <p class="text-left flex">Discount code</p>
                  </span>
                  <span class="rounded-xl">
                    <form @submit.prevent="validateDiscountCode(code)">
                      <div class="relative w-full">
                        <input
                          type="search"
                          placeholder="Enter your discount code"
                          ref="search"
                          :maxlength="maxChar"
                          :minlength="minChar"
                          v-model="code"
                          id='location-search"'
                          class="border-2 focus:border-custom-blue p-1.5 md:p-2.5 w-full h-10 md:h-12 z-20 text-sm bg-white rounded-xl"
                          required=""
                        />

                        <button
                          type="submit"
                          class="absolute top-0 right-0 py-1.5 md:py-2.5 px-3 h-10 md:h-12 text-sm font-medium text-custom-blue rounded-r-xl duration-300"
                        >
                          Apply
                        </button>
                      </div>
                    </form>
                  </span>
                  <form @submit.prevent="sendPaymentDetails()">
                    <div class="space-y-2 py-4">
                      <div class="flex justify-between">
                        <p class="text-gray-500">Subtotal</p>
                        <p>${{ this.mentorData.price }}</p>
                      </div>
                      <div class="flex justify-between">
                        <p class="text-gray-500">Discount</p>
                        <p>%{{ this.discountCurrent }}</p>
                      </div>

                      <div class="relative flex py-2.5 items-center">
                        <div class="flex-grow border-t border-gray-200"></div>
                      </div>
                      <div class="flex font-bold justify-between">
                        <p>Total</p>
                        <p>
                          ${{
                            this.mentorData.price -
                            (this.mentorData.price * this.discountCurrent) / 100
                          }}
                        </p>
                      </div>
                      <div class="flex font-extralight justify-between">
                        <small class="text-gray-400 text-xs text-left">
                          The price will be converted to AZN at the bank
                          exchange rate of the day.</small
                        >
                      </div>
                    </div>
                    <div class="flex justify-center w-full">
                      <button
                        class="bg-black text-white px-4 py-1.5 rounded-xl"
                      >
                        Pay now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>

      <div v-if="showCalendar == true">
        <v-date-picker
          is-expanded
          :attributes="attributes"
          @dayclick="onDayClick"
          v-model="date"
          mode="dateTime"
          :disabled-dates="
            { weekdays: this.avDays } || { weekdays: this.todayDate }
          "
          :min-date="new Date(new Date().getTime() + 60 * 60 * 24 * 1000)"
          :minute-increment="30"
          :valid-hours="this.avHours"
          is24hr
        />
        <h1 class="py-2"></h1>
      </div>
      <div
        v-if="showCalendar == true"
        class="flex flex-col justify-center align-middle items-center"
      >
        <p v-if="date == ''" class="font-extrabold text-md">
          Please choose a time slot to continue
        </p>
        <p v-if="date !== ''" class="font-extralight text-md">Chosen time</p>
        <p v-if="showPST == false" class="pt-3 pb-4 font-bold">
          {{ this.testDate }}
        </p>
        <p v-if="showPST == true && date !== ''" class="pt-3 pb-4 font-bold">
          {{ this.testDate }} PST timezone
        </p>
        <button
          :disabled="this.wrongTime === true || date === ''"
          @click="sendCalendarData"
          class="cursor-not-allowed disabled inline-flex items-center py-2 px-3 text-sm font-medium text-center rounded-full border-1 border-custom-blue text-custom-blue hover:bg-custom-blue hover:text-gray-50 duration-300"
        >
          Continue
        </button>
      </div>
      <div
        class="flex align-middle justify-center items-center content-center"
        v-show="showContact == true"
      >
        <form @submit.prevent="sendContactData">
          <div class="flex flex-col align-center justify-center">
            <h1
              class="text-xl md:text-2xl xl:text-3xl font-bold leading-10 text-gray-800 text-left md:mt-0 mb-4 mt-1 sm:mt-0"
            >
              Information for the mentor
            </h1>
          </div>
          <div class="flex flex-col align-center justify-center">
            <input
              type="text"
              class="border border-gray-400 rounded-2xl p-2"
              placeholder="Full name"
              v-model="fullName"
              required
            />
            <span class="py-1" />
            <input
              type="text"
              class="border border-gray-400 rounded-2xl p-2"
              placeholder="Email"
              v-model="email"
              required
            />
            <span class="py-1" />
            <input
              required
              type="tel"
              class="border border-gray-400 rounded-2xl p-2 appearance-none"
              placeholder="Phone number"
              v-model="phone"
            />
            <span class="py-1" />
            <input
              required
              type="text"
              class="border border-gray-400 rounded-2xl p-2"
              placeholder="Subject"
              v-model="industry"
            />
            <span class="py-1" />
            <input
              required
              type="url"
              class="border border-gray-400 rounded-2xl p-2"
              placeholder="Your LinkedIn profile"
              v-model="linkedinUrl"
            />
            <span class="py-1" />
            <textarea
              required
              class="border border-gray-400 rounded-2xl p-2"
              placeholder="What do you want to talk about?"
              v-model="message"
            ></textarea>
            <span class="py-1" />
            <input
              type="file"
              accept="application/pdf"
              class="border border-gray-400 bg-white text-gray-400 rounded-2xl p-2"
              placeholder="Upload CV"
              required
              @change="onFileSelected"
            />
            <span class="text-gray-400 text-xs italic text-left"
              >Upload CV (pdf)</span
            >
            <span class="py-1" />
          </div>
          <div class="w-full bottom-0 pt-2 flex justify-end">
            <button
              submit
              class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-custom-blue rounded-2xl hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Send
              <svg
                class="ml-2 -mr-1 w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { trackEvent } from "vue-gtm";
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
  name: "PopupMentorDetails",
  props: {
    modalFalse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrongTime: false,
      chosenDate: "",
      days: [],
      maxChar: 50,
      mentorData: "",
      fullName: "",
      email: "",
      phone: "",
      industry: "",
      message: "",
      linkedinUrl: "",
      file: null,
      date: "",
      avDays: [],
      avHours: [],
      avDates: {},
      showContact: false,
      showCalendar: true,
      showPayment: false,
      bookedDate: new Date(),
      todayDate: new Date(),
      scrollVisible: false,
      mentorfullName: "",
      testDate: "",
      showPST: false,
      timezone: "",
      discountCurrent: 0,
      total: 0,
    };
  },

  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },

  mounted() {
    this.getMentorData();
    this.autoFillUserData();
  },

  computed: {
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      this.splitDate();
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },

  filters: {
    formatDate(date) {
      return moment(date).format("dddd, MMMM Do YYYY, h:mm a");
    },
  },

  methods: {
    validateDiscountCode(code) {
      axios
        .post(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/coupon/check/",
          {
            couponCode: code,
          }
        )
        .then((response) => {
          console.log(response.data.discount);
          this.discountCurrent = response.data.discount;
          if (
            this.discountCurrent === undefined ||
            this.discountCurrent === null ||
            this.discountCurrent === 0 ||
            this.discountCurrent === ""
          ) {
            this.discountCurrent = 0;
          }

          this.total =
            this.mentorData.price -
            (this.mentorData.price * this.discountCurrent) / 100;
          console.log(this.total);
          console.log(this.discountCurrent);

          if (this.discountCurrent === 0) {
            this.showErrorToast("Invalid Discount Code");
          }
          if (response.data.message === "Coupon Applied") {
            this.showSuccessToast("Discount code already used");
          }
        })
        .catch((error) => {
          console.log(error);
          this.discountCurrent = 0;
          this.showErrorToast("Invalid Discount Code");
        });

      console.log(code);
    },

    validateEmail(email) {
      console.log(email);
      email = this.email;
      const re =
        //eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(email)) {
        return false;
      }
      return true;
    },
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    showToast() {
      this.toast.success("Message sent successfully! Please check your email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //close modal
      this.$emit("close");
    },
    autoFillUserData() {
      console.log(this.$root.uid);
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/mentee/" +
            this.$root.uid +
            "/"
        )
        .then((response) => {
          console.log(response);
          this.candDatas = response.data;
          this.fullName =
            this.candDatas.firstName + " " + this.candDatas.lastName;
          this.email = this.candDatas.email;
          this.phone = this.candDatas.phone;
          this.linkedinUrl = this.candDatas.linkedinUrl;
          this.cv = this.candDatas.cv;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendCalendarData() {
      this.showContact = true;
      this.showCalendar = false;
    },

    splitDate() {
      this.testDate = this.date.toString().slice(0, 24);
      // now remove seconds
      this.testDate = this.testDate.slice(0, -3);
      console.log(this.testDate);
    },

    getMentorData() {
      const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      this.currentURL = window.location.href;
      let counter = 0;
      let i = 0;
      while (counter < 4) {
        i++;
        if (this.currentURL[i] === "/") {
          counter++;
        }
      }
      let str = "";
      if (this.currentURL[this.currentURL.length] === "/")
        str = this.currentURL.slice(i + 1, this.currentURL.length - 1);
      else str = this.currentURL.slice(i + 1, this.currentURL.length);

      console.log(str);
      // take str and split it into first and last name
      let splitStr = str.split("-");
      this.mentorFirstName = splitStr[0];
      this.mentorLastName = splitStr[1];

      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/mentor/" +
            this.mentorFirstName +
            " " +
            this.mentorLastName
        )

        .then((response) => {
          this.mentorData = response.data;
          console.log(this.mentorData);
          // mentor full name
          this.mentorfullName =
            this.mentorData.firstName + " " + this.mentorData.lastName;
          console.log(this.mentorFirstName);
          console.log(this.mentorLastName);
          console.log(this.mentorData.price);
          this.total = this.mentorData.price;
          console.log(this.total);

          let availableTimes = this.mentorData.availability.split(";");
          console.log(availableTimes);

          const timezone = moment.tz.guess();
          console.log(timezone);
          this.timezone = timezone;

          const parsedTimes = availableTimes
            .map((time) => {
              const parts = time.split("|");
              if (parts.length !== 2) {
                // string is not in the correct format or is empty
                return null;
              }
              return {
                day: parts[0],
                times: parts[1].split(",").map(Number),
              };
            })
            .filter((time) => time !== null);

          // check if the user is in Azerbaijan
          if (timezone !== "Asia/Baku") {
            // convert times to user's timezone
            parsedTimes.map((time) => {
              time.times = time.times.map((hour) => {
                // create a date string in the ISO 8601 format
                const dateString = `2020-01-01T${hour}:00+04:00`;
                // convert date to user's timezone
                return moment.tz(dateString, timezone).format("HH");
              });
            });
          }

          const formattedTimes = parsedTimes.map(
            (time) => `${time.day}|${time.times.join(",").replace(/:00/g, "")}`
            // remove 00 from the end of the strings
          );
          console.log(formattedTimes);

          this.newMentorTags = this.mentorData.tags.split(",");
          for (let i = 0; i < this.newMentorTags.length; i++) {
            this.newMentorTags[i] = this.newMentorTags[i].trim();
          }
          this.mentorData.tags = this.newMentorTags;
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          let av = [];
          let bv = [];
          av.push(formattedTimes);
          bv.push(formattedTimes);
          console.log(av);
          console.log(bv);
          let avDays = [];
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          // transforming availability into object
          av[0].forEach((element) => {
            let test = element.slice(4, element.length);
            test = test.split(",");
            for (let i = 0; i < test.length; i++) test[i] = Number(test[i]);
            this.avDates[element.slice(0, 3)] = test; //available time slots in object form
          });
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //getting the available weekdays
          for (let i = 0; i < av.length; i++) {
            av[i].forEach((date) => {
              avDays.push(date.slice(0, 3));
            });
          }
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
          //transforming the shortcut weekdays into the array weekday indexes
          for (let i = 0; i < 7; i++) {
            if (!avDays.includes(weekDays[i])) {
              this.avDays.push(i + 1);
            }
          }
          this.isLoading = false;
        });
    },
    onDayClick(day) {
      console.log(day);
      const idx = this.days.findIndex((d) => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days = [];
        this.days.push({
          id: day.id,
          date: day.date,
        });
      }
      this.chosenDate = String(this.days[0].date).slice(0, 3);
      this.avHours = this.filterAvailability(this.chosenDate);
      console.log(this.avDays);
      let currentDate = new Date();
      if (
        this.days[0].date.getTime() <= currentDate.getTime() ||
        this.avDays.includes(this.days[0].date.getDay() + 1)
      ) {
        this.wrongTime = true;
        this.showErrorToast("Date is unavailable!");
      } else {
        this.bookedDate = this.days[0].date.toISOString();
        console.log(this.bookedDate, this.days[0].date);
        this.wrongTime = false;
      }
    },
    filterAvailability(day) {
      console.log(this.avDates[day]);
      // console.log(this.avDates);
      return this.avDates[day];
    },
    onFileSelected(event) {
      this.file = event.target.files[0];
    },

    async sendPaymentDetails() {
      console.log(this.fullName, this.email, this.total);
      console.log(this.mentorData);
      console.log(this.mentorData.userID);
      console.log(this.$root.uid);

      //total price is in AZN
      this.aznTotal = this.total * 1.7025;

      // make this.aznTotal 2 decimal places
      this.aznTotal = this.aznTotal.toFixed(2);
      console.log(this.aznTotal);

      const formData = new FormData();
      formData.append("fullName", this.fullName);
      formData.append("email", this.email);
      formData.append("mobilePhone", this.phone);
      formData.append("industry", this.industry);
      formData.append("talksAbout", this.message);
      formData.append("linkedinURL", this.linkedinUrl);
      formData.append("bookingDateStr", this.date);
      formData.append("file", this.file);
      formData.append(
        "bookingDate",
        new Date(
          new Date(this.date).getTime() + 1000 * 60 * 60 * 4
        ).toISOString()
      );

      formData.append("mentorFullName", this.mentorfullName);

      formData.append("mentorID", this.mentorData.userID);
      formData.append("menteeID", this.$root.uid);
      formData.append("amount", this.aznTotal);

      axios
        .post(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/login/payment/",
          formData
        )
        .then((response) => {
          console.log(response);
          this.paymentLink = response.data.res_checkout.url;
          window.location.href = this.paymentLink;
          console.log(this.paymentLink);

          const purchaseData = {
            event: "purchase",
            ecommerce: {
              purchase: {
                actionField: {
                  id: response.data.purchaseId, // Replace with the purchase ID from the response
                  revenue: this.aznTotal, // Replace with the purchase total
                  currency: "AZN", // Replace with the currency code
                },
                products: [
                  {
                    name: "Session", // Replace with the product name
                    id: this.$root.uid, // Replace with the product ID
                    price: this.aznTotal, // Replace with the product price
                    quantity: 1, // Replace with the product quantity
                  },
                ],
              },
            },
          };
          console.log(purchaseData);
          trackEvent(purchaseData);
        });
    },

    async sendContactData() {
      console.log(this.message);
      if (this.validateEmail(this.email) === false) {
        this.showErrorToast("Please enter a valid email address");
      } else {
        console.log(this.bookedDate);

        if (this.fullName === "" || this.email === "" || this.phone === "") {
          this.showErrorToast("Invalid request");
        } else {
          // const formData = new FormData();
          console.log(
            this.$root.uid,
            this.fullName,
            this.email,
            this.phone,
            this.message,
            this.mentorfullName,
            this.file,
            new Date(
              new Date(this.date).getTime() + 1000 * 60 * 60 * 4
            ).toISOString()
          );
          console.log(this.mentorfullName);
          (this.data = {
            menteeID: this.$root.uid,
            fullName: this.fullName,
            email: this.email,
            mobilePhone: this.phone,
            industry: this.industry,
            talksAbout: this.message,
            linkedinURL: this.linkedinUrl,
            bookingDateStr: this.date,
            file: this.file,
            bookingDate: new Date(
              new Date(this.date).getTime() + 1000 * 60 * 60 * 4
            ).toISOString(),
            mentorFullName: this.mentorfullName,
            mentorID: this.mentorData.userID,
          }),
            (this.showContact = false);
          this.showPayment = true;
        }
      }
    },
  },
};
</script>
