<template>
  <div
    class="w-full py-12 md:py-18 flex justify-center items-center align-middle px-6 md:px-32"
  >
    <div
      class="flex flex-col md:flex-row w-full justify-center align-middle items-center"
    >
      <div class="md:w-1/2 flex flex-col justify-start text-left space-y-8">
        <h1 class="bt-fontonly font-bold text-xl md:text-5xl text-custom-blue">
          Oops, Payment Declined!
        </h1>
        <h1 class="bt-fontonly text-md md:text-2xl">
          Whoops-a-daisy! It seems like there was a little hiccup with your
          payment, and we couldn't process it this time.
        </h1>
        <h1 class="bt-fontonly text-sm md:text-xl text-custom-gray">
          Possible reasons for the decline could be:

          <ul class="pb-4 text-gray-500">
            <li>• Insufficient funds</li>
            <li>• Incorrect card details</li>
            <li>• Card expired</li>
          </ul>
        </h1>
      </div>
      <div class="flex justify-center h-0 w-0 md:h-full md:w-1/2">
        <lottie-player
          src="https://lottie.host/7bbfa97d-e232-4b3a-b262-f11c6b2d54be/23m6meJKUl.json"
          background="transparent"
          speed="1"
          style="width: 500px; height: 500px"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div class="flex justify-center h-full w-full md:w-0 md:h-0">
        <lottie-player
          src="https://lottie.host/7bbfa97d-e232-4b3a-b262-f11c6b2d54be/23m6meJKUl.json"
          background="transparent"
          speed="1"
          style="width: 300px; height: 300px"
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import "@lottiefiles/lottie-player";

import Footer from "../components/Footer.vue";
import axios from "axios";
export default {
  components: { Footer },
  mounted() {
    this.failedPayment();
  },
  methods: {
    failedPayment() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/payment/fail"
        )
        .then((response) => {
          console.log(response);
        });
    },
  },
};
</script>

<style></style>
