import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap/dist/css/bootstrap.css";
import "./assets/tailwind.css";
import "./assets/main.css";
import router from "./router";
import Toast from "vue-toastification";
import moment from "moment";
import "vue-toastification/dist/index.css";
import Vuelidate from "vuelidate";
import "tw-elements";
import firebase from "firebase/compat/app";
import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";
import { SetupCalendar, Calendar, DatePicker } from "v-calendar";
//import { createMetaManager } from "vue-meta";
import VueGtag from "vue-gtag";
import VueGtm from "vue-gtm";

let app = "";
//const metaManager = createMetaManager();

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);
    app.use(Toast);

    app.use(moment);
    app.use(VCalendar, {});
    app.use(Vuelidate);
    app.use(SetupCalendar, {});
    app.component("Calendar", Calendar);
    app.component("DatePicker", DatePicker);
    app.use(VueGtm, {
      id: "GTM-MTGRCF9",
      defer: false,
      debug: true,
      loadScript: true,
    });
    app.use(VueGtag, {
      config: { id: "G-9GP19N8EC4" },
    });
    //app.config.globalProperties.$meta = metaManager;
    app.use(router);
    app.mount("#app");
  }
});
