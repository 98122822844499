<template>
  <div>
    <h2 class="text-3xl md:text-3xl font-bold md:mt-10 my-6">About us</h2>
    <div class="flex items-center justify-center">
      <!-- <div class="container mx-auto p-6 grid grid-cols-2 gap-4  items-center justify-center"> -->
 <div
        class="grid  md:px-12 md:grid-rows-3 md:grid-cols-2 grid-flow-row md:justify-center md:items-center "
      >


<div class="p-12">
  <div class="col-span-1 flex flex-col bg-white shadow-xl p-4 max-w-sm rounded-2xl bg-gradient-to-tl from-gray-200 to-blue-50">
    <a href="#" class="flex flex-col items-center pb-10">
        <img class="rounded-t-lg h-40 flex justify-center pt-8" src="../assets/SelectExpert.svg" alt="">
    </a>
    <div class="">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hirelamp is the vision of the easy and direct hiring</h5>
        </a>
        <p class="mb-3 px-8 font-normal text-gray-700 dark:text-gray-400">At Hirelamp, we believe there are a lot to be improved in the current recruitment and job search process. In this long journey, we will start with simple steps.
      </p>
    </div>
</div>
</div>
<div class="p-12">

  <div class="col-span-1 flex flex-col bg-white border-2 p-4 max-w-sm">
    <a href="#" class="flex flex-col items-center pb-10">
        <img class="rounded-t-lg h-40 flex justify-center pt-8" src="../assets/SelectExpert.svg" alt="">
    </a>
    <div class="">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hirelamp is the vision of the easy and direct hiring</h5>
        </a>
        <p class="mb-3 px-8 font-normal text-gray-700 dark:text-gray-400">At Hirelamp, we believe there are a lot to be improved in the current recruitment and job search process. In this long journey, we will start with simple steps.
      </p>
    </div>
</div>
</div>


<div class="p-12">

  <div class="col-span-1 flex flex-col bg-white border-2 p-4 max-w-sm">
    <a href="#" class="flex flex-col items-center pb-10">
        <img class="rounded-t-lg h-40 flex justify-center pt-8" src="../assets/SelectExpert.svg" alt="">
    </a>
    <div class="">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hirelamp is the vision of the easy and direct hiring</h5>
        </a>
        <p class="mb-3 px-8 font-normal text-gray-700 dark:text-gray-400">At Hirelamp, we believe there are a lot to be improved in the current recruitment and job search process. In this long journey, we will start with simple steps.
      </p>
    </div>
</div>
</div>

<div class="p-12">

  <div class="col-span-1 flex flex-col bg-white border-2 p-4 max-w-sm">
    <a href="#" class="flex flex-col items-center pb-10">
        <img class="rounded-t-lg h-40 flex justify-center pt-8" src="../assets/SelectExpert.svg" alt="">
    </a>
    <div class="">
        <a href="#">
            <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Hirelamp is the vision of the easy and direct hiring</h5>
        </a>
        <p class="mb-3 px-8 font-normal text-gray-700 dark:text-gray-400">At Hirelamp, we believe there are a lot to be improved in the current recruitment and job search process. In this long journey, we will start with simple steps.
      </p>
    </div>
</div>
</div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/Footer.vue";
export default {
  components: {
    Footer
  }
};

</script>

<style></style>
