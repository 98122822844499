<template>
  <div class="w-full h-full px-8">
    <div class="flex flex-col">
      <p class="bt-md text-left pb-8">Mentor's Feedback to you</p>

      <div class="divide-y space-y-4">
        <div class="flex flex-row">
          <div>
            <img
              :src="mentorData.menteePhoto"
              alt=""
              class="h-12 w-12 rounded-full mr-2"
            />
          </div>
          <div class="text-left">
            <p class="bt-placeholder">
              {{ mentorData.mentorFirstName }}
              {{ mentorData.mentorLastName }}
            </p>
            <p class="bt-placeholder text-gray-500">
              {{ mentorData.date }}
            </p>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-center py-10">
        <div class="w-full container border p-4 rounded-2xl max-w-full">
          <p class="text-2xl font-bold pb-8">
            {{ mentorData.feedbackTitle }}
          </p>
          <p class="text-left">{{ mentorData.feedbackText }}</p>
        </div>
        <div class="w-full py-8 flex justify-end">
          <button
            @click="
              bookMentorAgain(
                mentorData.mentorFirstName,
                mentorData.mentorLastName
              )
            "
            class="py-2 px-14 bg-custom-blue rounded-full flex justify-center items-center text-white"
          >
            <p class="text-white">Book again</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  props: ["mentorId"],
  data() {
    return {
      mentorData: "",
      rateMentorText: "",
      menteeDataID: "",
      ratingCoachability: 0,
      ratingPrior: 0,
      ratingPotential: 0,
      candId: "",
      feedbackTitle: "",
      feedbackText: "",
    };
  },

  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  beforeMount() {
    this.getSessionData();
  },

  methods: {
    showToast() {
      this.toast.success("Thank you for your feedback!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    showErrorToast() {
      this.toast.error("Something went wrong, please try again!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    bookMentorAgain(mentorFirstName, mentorLastName) {
      this.$router.push("/mentor/" + mentorFirstName + "-" + mentorLastName);
    },
    getSessionData() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/session/Past/" +
            localStorage.getItem("sessionID") +
            "/"
        )
        .then((response) => {
          console.log(response.data);
          this.mentorData = response.data;
          this.mentorData.date = this.mentorData.date.slice(0, 10);
          this.isLoading = false;
          console.log(this.mentorData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
