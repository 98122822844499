<template>
  <div>
    <div class="flex flex-col">
      <!-- Open modal Popup Calendar -->
      <div class="flex flex-col">
        <!-- create a table with names -->

        <div
          class="fixed z-50 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          v-if="openModal"
        >
          <div class="flex px-4 text-center sm:block sm:p-0">
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            <!-- This element is to trick the browser into centering the modal contents. -->
            <span
              class="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <!--
            Modal panel, show/hide based on modal state.
      
            Entering: "ease-out duration-300"
              From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              To: "opacity-100 translate-y-0 sm:scale-100"
            Leaving: "ease-in duration-200"
              From: "opacity-100 translate-y-0 sm:scale-100"
              To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          -->
            <div
              class="w-screen inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div class="bg-white px-4 pb-4 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 w-full sm:text-left">
                    <div class="flex justify-between pb-8">
                      <h3
                        class="text-lg leading-6 font-medium text-gray-900"
                        id="modal-headline"
                      >
                        Select available slots
                      </h3>
                      <!-- <button @click="this.openModal = false">
                        <img src="" alt="">
                      </button> -->
                      <button
                        type="button"
                        class="close font-bold"
                        @click="this.openModal = false"
                      >
                        <img src="../../assets/closeButton.svg" alt="" />
                      </button>
                    </div>
                    <div class="">
                      <PopupCalendar
                        @sendOption="fetchData"
                        @sendTillSettings="fetchTillData"
                        @close="closeModal"
                        :oldAvailableSlots="oldAvailableSlots"
                        :tillSettingsBackend="tillSettingsBackend"
                        :mentorID="mentorID"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center p-4 relative">
        <div class="relative">
          <div class="image-preview">
            <img
              v-if="newImage !== ''"
              class="preview rounded-full h-32 w-32"
              :src="newImage"
            />
            <img
              v-if="newImage == ''"
              class="preview rounded-full h-32 w-32"
              :src="mentorDatas.profileImg"
            />
          </div>
        </div>

        <div
          class="z-40 absolute rounded-full h-32 w-32 cursor-pointer"
          @click="imageInput"
        >
          <input
            type="file"
            id="imageInput"
            @change="previewImage"
            accept="image/*"
            hidden
          />

          <div
            class="absolute h-32 w-32 opacity-70 bg-gray-200 rounded-full"
          ></div>
          <img
            src="../../assets/camera.svg"
            alt=""
            class="rounded-full absolute h-12 w-12 flex justify-center align-middle items-center m-10 opacity-100"
          />
        </div>
      </div>
      <div>
        <p class="bt-md">
          {{ mentorDatas.firstName }} {{ mentorDatas.lastName }}
        </p>
      </div>
      <div class="pb-8">
        <ul
          class="justify-center nav nav-tabs flex flex-row flex-wrap list-none border-b-0 pl-0 mb-0 md:mb-4 space-x-4"
          id="tabs-tab"
          role="tablist"
        >
          <li
            class="nav-item duration-300 ease-in-out hover:text-blue-500"
            role="presentation"
            v-bind:class="{
              'text-gray-900 border-b-2  border-gray-200': openTab !== 1,
              'text-custom-blue border-b-2 border-custom-blue': openTab === 1,
            }"
          >
            <a
              class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
              v-on:click="toggleTabs(1)"
              >Personal information
            </a>
          </li>
          <li
            class="nav-item duration-300 ease-in-out hover:text-blue-500"
            role="presentation"
            v-bind:class="{
              'text-gray-900 border-b-2  border-gray-200': openTab !== 2,
              'text-custom-blue border-b-2 border-custom-blue': openTab === 2,
            }"
          >
            <a
              class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
              v-on:click="toggleTabs(2)"
              >Professional information</a
            >
          </li>
        </ul>
      </div>
      <div
        class="flex flex-col justify-center space-y-8 py-10 px-4 md:px-8"
        v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }"
        id="tabs-tabContent"
      >
        <div class="space-y-8 space-x-0 lg:space-y-0 lg:space-x-8 lg:flex">
          <input
            type="text"
            class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="First Name"
            v-model="firstName"
          />
          <input
            type="text"
            class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="Last Name"
            v-model="lastName"
          />
        </div>
        <div class="space-y-8 space-x-0 lg:space-y-0 lg:space-x-8 lg:flex">
          <div class="flex flex-col w-full">
            <input
              type="tel"
              class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
              placeholder="Phone number"
              v-model="mentorDatas.phoneNumber"
            />
            <span
              class="text-notif-orange text-xs italic text-left flex justify-start"
              >For internal purposes</span
            >
          </div>
          <div class="flex flex-col w-full">
            <input
              type="email"
              class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
              placeholder="Email"
              v-model="mentorDatas.email"
            />
            <span
              class="text-notif-orange text-xs italic text-left flex justify-start"
              >For internal purposes</span
            >
          </div>
        </div>

        <div class="w-full">
          <button
            @click="updateMentor(mentorDatas.userID)"
            class="py-2 px-14 bg-custom-blue rounded-full flex justify-center items-center text-white"
          >
            <p class="text-white">Save</p>
          </button>
        </div>
      </div>
      <div
        class=""
        v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }"
      >
        <div
          class="flex flex-col md:flex-row md:space-x-6 px-4 items-stretch justify-center"
        >
          <div class="flex flex-row space-x-4">
            <div class="flex flex-col space-y-2">
              <p class="text-left text-xs text-gray-400">Field</p>
              <input
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder="Field"
                v-model="position"
              />
            </div>
            <div class="flex flex-col space-y-2">
              <p class="text-left text-xs text-gray-400">Country or City</p>

              <input
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder="Country or City"
                v-model="country"
              />
            </div>
          </div>
          <div class="flex flex-row space-x-4">
            <div class="flex flex-col space-y-2">
              <p class="text-left text-xs text-gray-400">Current company</p>
              <input
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder="Company"
                v-model="currentCompany"
              />
            </div>
            <div class="flex flex-col space-y-2">
              <p class="text-left text-xs text-gray-400">
                Your services fee ($)
              </p>

              <input
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder="Your service fee"
                v-model="price"
              />
            </div>
          </div>
          <!-- </div> -->
        </div>

        <div
          class="flex flex-row py-8 px-4 space-x-8 items-stretch w-full justify-between"
        >
          <div class="">
            <div class="flex flex-col space-y-2">
              <p class="text-left text-xs text-gray-400">
                Choose your service(s)
              </p>

              <button
                @click="dropDownFunction()"
                id="dropdownCheckboxButton"
                class="text-white bg-custom-blue hover:bg-cyan-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
              >
                <span v-if="selectedCategories != ''"
                  >Update your service(s)</span
                >
                <span v-if="selectedCategories == ''">
                  Select at least one
                </span>

                <svg
                  class="ml-2 w-4 h-4"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>
            </div>
            <!-- Dropdown menu -->
            <div
              v-if="this.dropDown == true"
              id="dropdownDefaultCheckbox"
              class="z-10 w-full bg-white rounded-b-2xl divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
            >
              <ul
                class="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200"
              >
                <li>
                  <div class="flex items-center">
                    <input
                      id="checkbox-item-1"
                      type="checkbox"
                      placegolder="Services"
                      value="Job Search Consultation"
                      v-model="selectedCategories"
                      class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="checkbox-item-1"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Job search consultation
                    </label>
                  </div>
                </li>
                <li>
                  <div class="flex items-center">
                    <input
                      id="checkbox-item-2"
                      type="checkbox"
                      value="Career Coaching"
                      v-model="selectedCategories"
                      class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="checkbox-item-2"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Career coaching</label
                    >
                  </div>
                </li>
                <li>
                  <div class="flex items-center">
                    <input
                      id="checkbox-item-3"
                      type="checkbox"
                      value="Interview Preparation"
                      v-model="selectedCategories"
                      class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      for="checkbox-item-3"
                      class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >Interview Preparation</label
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="flex flex-col space-y-2">
            <p class="text-left text-xs text-gray-400">Choose time slots</p>

            <button
              @click="this.openModal = true"
              class="text-white bg-custom-blue hover:bg-cyan-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              <span v-if="this.mentorDatas.availability != ''"
                >Update your available time</span
              >
              <span v-if="this.mentorDatas.availability == ''">
                Select your available time
              </span>
            </button>
          </div>
        </div>

        <div class="px-4 pb-2">
          <p class="font-bold text-left pb-2">About me</p>
          <div class="flex flex-row space-x-4 w-full">
            <div class="flex flex-col justify-center w-full space-y-2">
              <p class="text-left text-xs text-gray-400">Bio</p>
              <textarea
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder="Short and clear description about you"
                v-model="bio"
              />
            </div>
            <div class="flex flex-col w-full space-y-2">
              <p class="text-left text-xs text-gray-400">My expertise</p>

              <textarea
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder=" Example: Google, Facebook, etc"
                v-model="expertise"
              />
            </div>
          </div>
          <div class="flex flex-row space-x-4 pt-8">
            <div class="flex flex-col w-full space-y-2">
              <p class="text-left text-xs text-gray-400">My experience</p>
              <textarea
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder="Example: 5 years of experience in IT"
                v-model="experience"
              />
            </div>
            <div class="flex flex-col w-full space-y-2">
              <p class="text-left text-xs text-gray-400">My education</p>

              <textarea
                type="text"
                class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                placeholder="Example: BSc from University of Indonesia"
                v-model="education"
              />
            </div>
          </div>
        </div>
        <div class="w-full py-8 px-4">
          <button
            @click="updateMentor()"
            class="py-2 px-14 bg-custom-blue rounded-full flex justify-center items-center text-white"
          >
            <p class="text-white">Save</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { useToast } from "vue-toastification";

import PopupCalendar from "../MentorDashboard/PopupCalendar.vue";
export default {
  data() {
    return {
      mentorDatas: "",
      file: null,
      newImage: "",
      openTab: 1,
      dropDown: false,
      openModal: false,
      careerCoaching: false,

      selectedCheckbox: [],
      selectedCategories: [],
      newDate: [],
      oldAvailableSlots: [],
      tillSettingsBackend: "",
      mentorID: "",
      lastName: "",
      firstName: "",
      email: "",
      country: "",
      bio: "",
      currentCompany: "",
      services: "",
      expertise: "",
      experience: "",
      education: "",
      price: "",
      tags: "",
      availability: "",
      position: "",
      timezone: "",
      availabilityDuration: "",
    };
  },
  components: {
    PopupCalendar,
  },

  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },

  beforeMount() {
    this.getMentorUserID();
  },

  methods: {
    showToast() {
      this.toast.success("Changes saved successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    showWarningToast() {
      this.toast.warning("Please fill all the fields to start verification!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    ifAnyFieldEmpty() {
      if (
        this.mentorDatas.firstName == "" ||
        this.mentorDatas.lastName == "" ||
        this.mentorDatas.phoneNumber == "" ||
        this.mentorDatas.email == "" ||
        this.mentorDatas.bio == "" ||
        this.mentorDatas.currentCompany == "" ||
        this.mentorDatas.services == "" ||
        this.mentorDatas.expertise == "" ||
        this.mentorDatas.experience == "" ||
        this.mentorDatas.education == "" ||
        this.mentorDatas.price == "" ||
        this.mentorDatas.tags == "" ||
        this.mentorDatas.availability == "" ||
        this.mentorDatas.position == "" ||
        this.mentorDatas.location == "" ||
        this.mentorDatas.timezone == "" ||
        this.mentorDatas.availabilityDuration == ""
      ) {
        this.showWarningToast();
        // this.showToast();
      } else {
        this.showToast();
      }
    },

    closeModal() {
      this.openModal = false;
    },

    fetchTillData(data) {
      this.mentorDatas.availabilityDuration = data;
    },

    fetchData(data) {
      console.log(data);
      this.newDate = data;
      this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.mentorDatas.timezone = this.timezone;

      this.mentorDatas.availability = this.newDate;

      console.log(this.mentorDatas.availability);
    },

    dropDownFunction() {
      this.dropDown = !this.dropDown;
    },

    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },
    previewImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.mentorDatas.profileImg = input.files[0];
      }
    },

    async imageInput() {
      document.getElementById("imageInput").click();
    },

    async updateMentor() {
      if (this.newImage) {
        // reader = new FileReader();
        console.log(this.mentorDatas.profileImg);
        console.log(this.newImage);
        const reader = new FileReader();
        reader.readAsDataURL(this.mentorDatas.profileImg);
        reader.onload = () => {
          this.mentorDatas.profileImg = reader.result;

          fetch(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/updateMentor/" +
              this.userID +
              "/",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                profileImg: this.newImage,
              }),
            }
          );
        };
      }

      console.log(this.mentorDatas.phoneNumber);
      console.log(this.mentorDatas.availability);
      const formData = new FormData();
      console.log(this.mentorDatas.availabilityDuration);
      this.mentorDatas.category = this.selectedCategories.toString();
      this.mentorDatas.category = this.mentorDatas.category.replace(/,/g, ", ");
      console.log(this.mentorDatas.category);
      console.log(this.selectedCategories.toString());

      console.log(this.firstName);
      console.log(this.lastName);
      console.log(this.phoneNumber);

      formData.append("firstName", this.firstName);
      formData.append("lastName", this.lastName);
      formData.append("phoneNumber", this.mentorDatas.phoneNumber);
      formData.append("email", this.email);
      formData.append("bio", this.bio);
      formData.append("currentCompany", this.currentCompany);
      formData.append("services", this.mentorDatas.services);
      formData.append("category", this.mentorDatas.category);
      formData.append("expertise", this.expertise);
      formData.append("experience", this.experience);
      formData.append("education", this.education);
      formData.append("price", this.price);
      formData.append("tags", this.tags);
      formData.append("availability", this.mentorDatas.availability);
      formData.append("position", this.position);
      formData.append("location", this.country);
      formData.append("timezone", this.timezone);
      formData.append(
        "availabilityDuration",
        this.mentorDatas.availabilityDuration
      );
      this.ifAnyFieldEmpty();

      setTimeout(() => {
        axios
          .put(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/updateMentor/" +
              this.userID +
              "/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            console.log(res);
            setTimeout(() => {
              location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },

    async getMentorUserID() {
      localStorage.getItem("userID")
        ? (this.userID = localStorage.getItem("userID"))
        : this.showErrorToast();
      this.getMentorData(this.userID);
    },

    async getMentorData(userID) {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/expert/profile/" +
            userID +
            "/"
        )
        .then((response) => {
          this.mentorDatas = response.data;
          console.log(this.mentorDatas);
          if (this.mentorDatas && this.mentorDatas.category) {
            this.selectedCategories = this.mentorDatas.category.split(", ");
          }

          this.firstName = this.mentorDatas.firstName;
          this.lastName = this.mentorDatas.lastName;
          this.phoneNumber = this.mentorDatas.phoneNumber;
          this.email = this.mentorDatas.email;
          this.country = this.mentorDatas.location;
          this.bio = this.mentorDatas.bio;
          this.currentCompany = this.mentorDatas.currentCompany;
          this.services = this.mentorDatas.services;
          this.category = this.mentorDatas.category;
          this.expertise = this.mentorDatas.expertise;
          this.experience = this.mentorDatas.experience;
          this.education = this.mentorDatas.education;
          this.price = this.mentorDatas.price;
          this.tags = this.mentorDatas.tags;
          this.availability = this.mentorDatas.availability;
          this.position = this.mentorDatas.position;
          this.timezone = this.mentorDatas.timezone;
          this.availabilityDuration = this.mentorDatas.availabilityDuration;

          this.oldAvailableSlots = this.mentorDatas.availability;
          console.log(this.oldAvailableSlots);

          this.tillSettingsBackend = this.mentorDatas.availabilityDuration;
          console.log(this.tillSettingsBackend);

          this.mentorID = this.mentorDatas.userID;
          console.log(this.mentorID);

          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendNewUserData() {
      const formData = new FormData();
      formData.append("profileImg", this.file);
      formData.append("firstName", this.mentorDatas.firstName);
      formData.append("lastName", this.mentorDatas.lastName);
      formData.append("phoneNumber", this.mentorDatas.phoneNumber);
      formData.append("email", this.mentorDatas.email);
      axios
        .put(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/expert/profile/" +
            this.userID +
            "/",
          formData
        )
        .then((response) => {
          this.mentorDatas = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.showErrorToast("Something went wrong, please try again later");
        });
    },
  },
};
</script>

<style></style>
