<template>
  <div class="p-3">
    <div>
      <p class="bt-md text-left text-gray-800">Sessions</p>
    </div>
    <ul
      class="nav nav-tabs flex flex-row flex-wrap list-none border-b-0 pl-0 mb-0 md:mb-4 space-x-4"
      id="tabs-tab"
      role="tablist"
    >
      <li
        class="nav-item duration-300 ease-in-out hover:text-blue-500"
        role="presentation"
        v-bind:class="{
          'text-gray-900 border-b-2  border-gray-200': openTab !== 1,
          'text-custom-blue border-b-2 border-custom-blue': openTab === 1,
        }"
      >
        <a
          class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
          v-on:click="toggleTabs(1)"
        >
          <span class="pr-1.5">Upcoming</span>

          <span
            v-if="upcomingSessions > 0"
            class="text-xs px-1.5 text-white bg-red-600 rounded-full"
            >{{ upcomingSessions }}</span
          >
        </a>
      </li>
      <li
        class="nav-item duration-300 ease-in-out hover:text-blue-500"
        role="presentation"
        v-bind:class="{
          'text-gray-900 border-b-2  border-gray-200': openTab !== 2,
          'text-custom-blue border-b-2 border-custom-blue': openTab === 2,
        }"
      >
        <a
          class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
          v-on:click="toggleTabs(2)"
          >Past</a
        >
      </li>
      <li
        class="nav-item duration-300 ease-in-out hover:text-blue-500"
        role="presentation"
        v-bind:class="{
          'text-gray-900 border-b-2  border-gray-200': openTab !== 3,
          'text-custom-blue border-b-2 border-custom-blue': openTab === 3,
        }"
      >
        <a
          class="cursor-pointer px-2 block font-medium text-sm leading-tight my-2"
          v-on:click="toggleTabs(3)"
          >All</a
        >
      </li>
    </ul>
    <div class="tab-content" id="tabs-tabContent">
      <div v-bind:class="{ hidden: openTab !== 1, block: openTab === 1 }">
        <!-- list of upcoming sessions -->

        <div v-if="noSessionsUpcoming == true" class="flex justify-center h-96">
          <div
            class="flex flex-col justify-center align-middle content-center items-center"
          >
            <img
              src="../../assets/noSessionsNew.svg"
              class="justify-center align-middle content-center items-center z-50"
              alt=""
            />
            <img
              src="../../assets/noSessionsNew2.svg"
              class="justify-center align-middle content-center items-center -mt-48 transform hover:rotate-45 transition-transform duration-500 ease-in-out"
              alt=""
            />
            <p class="text-center bt-book py-8">No upcoming sessions</p>
          </div>
        </div>

        <ul v-if="noSessionsUpcoming == false">
          <li
            class="flex py-2"
            v-for="candData in candDatas"
            :key="candData.userID"
            :currentMentorId="candData.mentorID"
          >
            <div
              class="w-full flex flex-row rounded-2xl py-2 md:px-6 bg-gray-50"
            >
              <div class="py-2">
                <img
                  :src="candData.menteePhoto"
                  alt=""
                  class="h-12 w-12 rounded-full mr-2"
                />
              </div>
              <div class="w-full p-2 h-100">
                <div class="flex items-stretch justify-between">
                  <div>
                    <p class="bt-bold text-left">
                      {{ candData.sessionTopic }}
                    </p>
                  </div>
                  <div>
                    <p class="bt-bold text-custom-blue">
                      ${{ candData.sessionPrice }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between">
                  <div>
                    <p class="bt-placeholder text-left">
                      With
                      {{ candData.mentee_name }}
                    </p>
                  </div>
                  <div>
                    <p class="bt-placeholder">
                      {{ formatDate(candData.date) }} | {{ candData.time }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-end pt-8">
                  <button
                    v-if="candData.confirmedMentor === 'False'"
                    @click="confirmSession(candData.sessionID)"
                    class="bg-custom-blue text-white px-2 py-1.5 rounded-xl"
                  >
                    Confirm Session
                  </button>

                  <button
                    :disabled="
                      isButtonDisabled(candData.sessionID, candData.date)
                    "
                    v-if="
                      candData.confirmedMentor === 'True' &&
                      !isButtonDisabled(candData.sessionID, candData.date)
                    "
                    @click="this.createRoom(candData.sessionID)"
                    class="bg-custom-blue text-white px-2 py-1.5 rounded-xl"
                  >
                    Join Session
                  </button>

                  <span
                    v-if="
                      candData.confirmedMentor === 'True' &&
                      isButtonDisabled(candData.sessionID, candData.date)
                    "
                    class="flex flex-col justify-end items-end"
                  >
                    <button
                      :disabled="
                        isButtonDisabled(candData.sessionID, candData.date)
                      "
                      v-if="
                        candData.confirmedMentor === 'True' &&
                        isButtonDisabled(candData.sessionID, candData.date)
                      "
                      @click="this.createRoom(candData.sessionID)"
                      class="bg-gray-500 text-white px-2 py-1.5 rounded-xl cursor-not-allowed"
                    >
                      Pending
                    </button>
                    <small class="text-custom-blue">
                      The link will be available 5 minutes before the session
                    </small>
                  </span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div v-bind:class="{ hidden: openTab !== 2, block: openTab === 2 }">
      <div v-if="noSessionsPast == true" class="flex justify-center h-96">
        <div
          class="flex flex-col justify-center align-middle content-center items-center"
        >
          <img
            src="../../assets/noSessionsNew.svg"
            class="justify-center align-middle content-center items-center z-50"
            alt=""
          />
          <img
            src="../../assets/noSessionsNew2.svg"
            class="justify-center align-middle content-center items-center -mt-48 transform hover:rotate-45 transition-transform duration-500 ease-in-out"
            alt=""
          />
          <p class="text-center bt-book py-8">No past sessions</p>
        </div>
      </div>

      <ul v-if="noSessionsPast == false">
        <li
          class="flex py-2"
          v-for="pastCandData in pastCandDatas"
          :key="pastCandData.userID"
        >
          <div class="w-full flex flex-row rounded-2xl py-2 md:px-6 bg-gray-50">
            <div class="py-2">
              <img
                :src="pastCandData.menteePhoto"
                alt=""
                class="h-12 w-12 rounded-full mr-2"
              />
            </div>
            <div class="w-full p-2 h-100">
              <div class="flex items-stretch justify-between">
                <div>
                  <p class="bt-bold text-left">
                    {{ pastCandData.sessionTopic }}
                  </p>
                </div>
                <div>
                  <p class="bt-bold text-custom-blue">
                    ${{ pastCandData.sessionPrice }}
                  </p>
                </div>
              </div>
              <div class="flex flex-row justify-between">
                <div>
                  <p class="bt-placeholder">
                    From {{ pastCandData.menteeFirstName }}
                    {{ pastCandData.menteeLastName }}
                  </p>
                </div>
                <div>
                  <p class="bt-placeholder">{{ pastCandData.date }}</p>
                </div>
              </div>

              <div class="flex flex-row justify-between pt-8">
                <div>
                  <button
                    v-if="
                      pastCandData.feedbackTitle === '' &&
                      pastCandData.feedbackText === ''
                    "
                    @click="rateCandidate(pastCandData.sessionID)"
                    class="bg-custom-blue text-white rounded-full flex justify-center items-center"
                  >
                    <img
                      src="../../assets/rateMentor.svg"
                      alt=""
                      class="align-middle ml-2"
                    />
                    <p class="bt-button px-2 py-2.5">Give feedback</p>
                    <!-- </router-link> -->
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-bind:class="{ hidden: openTab !== 3, block: openTab === 3 }">
      <div v-if="noSessionsAll == true" class="flex justify-center h-96">
        <div
          class="flex flex-col justify-center align-middle content-center items-center"
        >
          <img
            src="../../assets/noSessionsNew.svg"
            class="justify-center align-middle content-center items-center z-50"
            alt=""
          />
          <img
            src="../../assets/noSessionsNew2.svg"
            class="justify-center align-middle content-center items-center -mt-48 transform hover:rotate-45 transition-transform duration-500 ease-in-out"
            alt=""
          />
          <p class="text-center bt-book py-8">No sessions</p>
        </div>
      </div>
      <ul v-if="noSessionsAll == false">
        <li
          class="flex py-2"
          v-for="allCandData in allCandDatas"
          :key="allCandData.userID"
        >
          <div class="w-full flex flex-row rounded-2xl p-2 bg-gray-100">
            <div class="py-2">
              <img
                :src="allCandData.menteePhoto"
                alt=""
                class="h-12 w-12 rounded-full mx-2"
              />
            </div>
            <div class="w-full p-2 h-100">
              <div class="flex items-stretch justify-between">
                <div>
                  <p class="bt-bold text-left">
                    {{ allCandData.sessionTopic }}
                  </p>
                </div>
                <div>
                  <p class="bt-bold text-custom-blue">
                    ${{ allCandData.sessionPrice }}
                  </p>
                </div>
              </div>
              <div class="flex flex-row justify-between">
                <div>
                  <p>
                    {{ allCandData.menteeFirstName }}
                    {{ allCandData.menteeLastName }}
                  </p>
                </div>
                <div>
                  <p>{{ allCandData.date }}</p>
                </div>
              </div>

              <div v-if="allCandData.timeFlag == 'Past'">
                <button
                  v-if="
                    allCandData.feedbackTitle === '' &&
                    allCandData.feedbackText === ''
                  "
                  @click="rateCandidate(allCandData.sessionID)"
                  class="bg-custom-blue text-white rounded-full flex justify-center items-center"
                >
                  <img
                    src="../../assets/rateMentor.svg"
                    alt=""
                    class="align-middle ml-2"
                  />
                  <p class="bt-button px-2 py-2.5">Give feedback</p>
                  <!-- </router-link> -->
                </button>
              </div>

              <div
                v-if="allCandData.timeFlag == 'Upcoming'"
                class="flex flex-row justify-end pt-8"
              >
                <button
                  v-if="allCandData.confirmedMentor === 'False'"
                  @click="confirmSession(allCandData.sessionID)"
                  class="bg-custom-blue text-white px-2 py-1.5 rounded-xl"
                >
                  Confirm Session
                </button>

                <button
                  :disabled="isButtonDisabled(allCandData.sessionID)"
                  v-else-if="allCandData.confirmedMentor === 'True'"
                  @click="this.createRoom(allCandData.sessionID)"
                  class="bg-custom-blue text-white px-2 py-1.5 rounded-xl"
                >
                  Join Session
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import io from "socket.io-client";
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
  data() {
    return {
      candDatas: "",
      pastCandDatas: "",
      allCandDatas: "",
      openTab: 1,
      upcomingSessions: 0,
      buttonText: "",
      buttonTextBool: true,
      noSessionsUpcoming: false,
      noSessionsPast: false,
      noSessionsAll: false,
      sessionTime: "",
      apiKey: process.env.VUE_APP_DAILY_API_KEY,
    };
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },

  mounted() {
    this.getUpcomingCandidateData();
    this.getPastCandidateData();
    this.getAllCandidateData();
    setTimeout(() => {
      this.getUpcomingCandidateData();
      this.getPastCandidateData();
      this.getAllCandidateData();
    }, 5000);
  },

  methods: {
    formatDate(date) {
      return new Date(date)
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })
        .replace(/\//g, "-");
    },
    currentTime() {
      return new Date();
    },
    minutesDifference(session, date) {
      const timeDifference = date - this.currentTime();
      console.log(timeDifference);
      return Math.floor(timeDifference / 1000 / 60);
    },

    isButtonDisabled(session, date) {
      const currentTime = new Date();
      const sessionTime = new Date(date);
      const timeDifference = sessionTime - currentTime;
      const minutesDifference = Math.floor(timeDifference / 1000 / 60);
      console.log(minutesDifference);
      return minutesDifference > 300;
    },

    confirmSession(sessionID) {
      axios
        .post(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/session/confirm/mentor/" +
            sessionID +
            "/",
          {
            status: true,
          }
        )
        .then((response) => {
          console.log(response);
          window.location.reload();
        });
    },

    async createMeetingToken() {
      const url = "https://api.daily.co/v1/meeting-tokens";

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.apiKey}`,
        },
        body: JSON.stringify({
          properties: {
            start_cloud_recording: true,
            is_owner: true,
          },
        }),
      };

      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();
        console.log(data);
        console.log(data.token);
        localStorage.setItem("token", data.token);
      } catch (error) {
        console.error("Error:", error);
      }
    },

    createRoom(sessionID) {
      console.log(sessionID);
      localStorage.setItem("sessionID", sessionID);
      const exp = Math.round(Date.now() / 1000) + 60 * 60;

      this.createMeetingToken();
      // Make an API call to create a room
      fetch("https://api.daily.co/v1/rooms", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.apiKey}`,
        },
        body: JSON.stringify({
          properties: {
            enable_recording: "cloud",
            exp,
            enable_chat: true,
            enable_prejoin_ui: true,
            enable_knocking: true,
          },
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          const roomUrl = data.url;
          console.log("Room URL:", roomUrl);
          this.roomUrl = roomUrl;
          this.sendRoomURL(roomUrl);
          localStorage.setItem("roomUrl", roomUrl);

          this.$router.push("/session");
          // store roomUrl in localStorage
        })
        .catch((error) => {
          console.error("Error creating room:", error);
        });
    },

    sendRoomURL(roomUrl) {
      console.log(roomUrl);
      axios
        .post(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/set-room/" +
            localStorage.getItem("sessionID") +
            "/",
          {
            link: roomUrl,
          }
        )
        .then((response) => {
          console.log(response);
        });
    },

    showWarningToast() {
      this.toast.warning("Please fill the feedback form from your email!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
    },

    async getCandidateUserID() {
      localStorage.getItem("userID")
        ? (this.userID = localStorage.getItem("userID"))
        : null;
      console.log(this.userID);
      this.getCandidateData(this.userID);
    },

    async rateCandidate(sessionId) {
      localStorage.setItem("sessionID", sessionId);
      this.$router.push("/mentorFeedback/" + localStorage.getItem("sessionID"));
    },

    async getUpcomingCandidateData() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/upcomingSessions/" +
            localStorage.getItem("userID") +
            "/"
        )
        .then((response) => {
          this.candDatas = response.data;
          console.log(this.candDatas);
          this.isLoading = false;

          // get time in hh:mm format from the date which is in 2023-05-22T13:30:00Z format. the time can end with :00 or :30
          this.candDatas.forEach((element) => {
            this.sessionTime = element.date;
            console.log(this.sessionTime);
            element.time = element.date.split("T")[1].split(":")[0] + ":00";
            console.log(element.time);
            this.time = element.date.split("T")[1].split(":")[0] + ":00";
            this.date = element.date.split("T")[0];
            console.log(element.date);
          });

          // get mentee name and surname
          this.candDatas.forEach((element) => {
            element.menteeFirstName = element.mentee_name.split(" ")[0];
            element.menteeLastName = element.mentee_name.split(" ")[1];
            this.menteeFirstName = element.mentee_name.split(" ")[0];
            this.menteeLastName = element.mentee_name.split(" ")[1];
          });

          // the number of upcoming sessions
          this.upcomingSessions = this.candDatas.length;
          if (this.upcomingSessions == 0) {
            this.noSessionsUpcoming = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getPastCandidateData() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/pastSessions/" +
            localStorage.getItem("userID") +
            "/"
        )
        .then((response) => {
          this.pastCandDatas = response.data;
          console.log(this.pastCandDatas);

          this.isLoading = false;
          // split date and time and convert date to dd/mm/yyyy and time to hh:mm
          this.pastCandDatas.forEach((element) => {
            element.date = element.date.split("T")[0];
          });
          if (this.pastCandDatas.length == 0) {
            this.noSessionsPast = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async getAllCandidateData() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/getAllSessionMentor/" +
            localStorage.getItem("userID") +
            "/"
        )

        .then((response) => {
          this.allCandDatas = response.data;
          console.log(this.allCandDatas);
          this.isLoading = false;
          this.allCandDatas.forEach((element) => {
            element.date = element.date.split("T")[0];
          });

          console.log(this.buttonText);
          if (this.allCandDatas.length == 0) {
            this.noSessionsAll = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
