<template>
  <div class="w-full h-full flex flex-row">
    <div class="w-1/2">
      <img src="../assets/macbook.jpg" alt="" />
    </div>
    <div class="w-1/2">
      <form @submit.prevent="submitHandler">
        <div class="flex flex-col items-center">
          <p class="px-20 text-left py-8">
            Vüsalə hal-hazırda Diageo şirkətində Lead Product Analyst olaraq
            çalışır. 8 illik təcrübəyə malik olan Vüsalə maliyyə sahəsində
            təhsil almış və 5 il boyunca GE Capital, GE Healthcare və Citibank
            kimi nüfuzlu şirkətlərdə çalışıb. O, bu müddət ərzində Reporting,
            Data Transformation, Report Automation, Data Visualization
            sahələrində ixtisaslaşıb və tədricən Data və Analitika sahəsinə
            keçid edib.
          </p>
          <div class="flex space-x-8">
            <div class="file-upload">
              <p>Upload your CV</p>
              <label for="file" class="file-label">
                <input
                  type="file"
                  id="file"
                  class="hidden"
                  @change="handleFileChange"
                />
                <div class="file-input">
                  <div class="flex items-center">
                    <svg
                      class="file-icon mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    <span
                      class="file-text"
                      :class="{ 'text-gray-500': !selectedFile }"
                    >
                      {{
                        selectedFile ? selectedFile.name : "Choose a file..."
                      }}
                    </span>
                  </div>
                  <button
                    v-if="selectedFile"
                    class="bg-blue-500 text-white px-2 py-1 rounded-md text-sm"
                    @click="resetFile"
                  >
                    Change File
                  </button>
                </div>
              </label>
            </div>
            <div class="file-upload">
              <p>Upload your JD</p>

              <label for="file" class="file-label">
                <input
                  type="file"
                  id="file"
                  class="hidden"
                  @change="handleFileChange"
                />
                <div class="file-input">
                  <div class="flex items-center">
                    <svg
                      class="file-icon mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                    <span
                      class="file-text"
                      :class="{ 'text-gray-500': !selectedFile }"
                    >
                      {{
                        selectedFile ? selectedFile.name : "Choose a file..."
                      }}
                    </span>
                  </div>
                  <button
                    v-if="selectedFile"
                    class="bg-blue-500 text-white px-2 py-1 rounded-md text-sm"
                    @click="resetFile"
                  >
                    Change File
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>
      </form>
      <div class="flex justify-end py-8 px-20">
        <button class="px-4 py-1.5 rounded-lg bg-custom-blue text-white">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedFile: null,
      response: null,
    };
  },
  methods: {
    handleFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    resetFile() {
      this.selectedFile = null;
      // Clear the input value
      const fileInput = this.$refs.fileInput;
      if (fileInput) {
        fileInput.value = "";
      }
    },
  },
};
</script>

<style>
.file-upload {
  display: inline-block;
}

.file-label {
  cursor: pointer;
}

.file-input {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.file-icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: currentColor;
}

.file-text {
  font-size: 0.875rem;
  color: #4a5568;
}
</style>
