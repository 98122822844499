<template>
  <div class="overflow-hidden bg-white w-671 p-2 md:p-9 pt-4 rounded-xl">
    <div class="">
      <div class="flex justify-between">
        <button type="button" class="close font-bold" @click="$emit('close')">
          <img src="../assets/closeButton.svg" alt="Close button" />
        </button>
      </div>
    </div>

    <div class="w-full md:w-full justify-center md:space-y-8 pb-8 px-4">
      <p>Lorem Ipsum</p>
      <p class="font-light">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
export default {
  name: "PopupMentorDetails",
  props: {
    modalFalse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      wrongTime: false,
      chosenDate: "",
      days: [],
      maxChar: 50,
      mentorData: "",
      fullName: "",
      email: "",
      phone: "",
      industry: "",
      message: "",
      linkedinUrl: "",
      file: null,
      date: "",
      avDays: [],
      avHours: [],
      avDates: {},
      showContact: false,
      showCalendar: true,
      showPayment: false,
      bookedDate: new Date(),
      todayDate: new Date(),
      scrollVisible: false,
      mentorfullName: "",
      testDate: "",
      showPST: false,
      timezone: "",
      discountCurrent: 0,
      total: 0,
    };
  },

  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },

  methods: {
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    showToast() {
      this.toast.success("Message sent successfully! Please check your email", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      //close modal
      this.$emit("close");
    },
  },
};
</script>
