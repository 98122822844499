<template>
  <div>
    <div class="flex flex-col">
      <div class="flex justify-center p-4 relative">
        <div class="relative">
          <div class="image-preview">
            <img
              v-if="newImage !== ''"
              class="preview rounded-full h-32 w-32"
              :src="newImage"
            />
            <img
              v-if="newImage == ''"
              class="preview rounded-full h-32 w-32"
              :src="candDatas.profileImg"
            />
          </div>
        </div>

        <div
          class="z-50 absolute rounded-full h-32 w-32 cursor-pointer"
          @click="imageInput"
        >
          <input
            type="file"
            id="imageInput"
            @change="previewImage"
            accept="image/*"
            hidden
          />

          <div
            class="absolute h-32 w-32 opacity-70 bg-gray-200 rounded-full"
          ></div>
          <img
            src="../../assets/camera.svg"
            alt=""
            class="rounded-full absolute h-12 w-12 flex justify-center align-middle items-center m-10 opacity-100"
          />
        </div>
      </div>
      <div>
        <p>{{ candDatas.firstName }} {{ candDatas.lastName }}</p>
      </div>
      <div class="flex flex-col justify-center space-y-8 py-10 px-4 md:px-8">
        <div class="space-y-8 space-x-0 lg:space-y-0 lg:space-x-8 lg:flex">
          <input
            type="text"
            class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="First Name"
            v-model="candDatas.firstName"
          />
          <input
            type="text"
            class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="Last Name"
            v-model="candDatas.lastName"
          />
        </div>
        <div class="space-y-8 space-x-0 lg:space-y-0 lg:space-x-8 lg:flex">
          <input
            type="tel"
            class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="Phone number"
            v-model="candDatas.phone"
          />
          <input
            type="email"
            class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
            placeholder="Email"
            v-model="candDatas.email"
          />
        </div>
        <div class="space-y-8 space-x-0 lg:space-y-0 lg:space-x-8 lg:flex">
          <div class="flex flex-col w-full">
            <input
              type="url"
              class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
              placeholder="Linkedin URL"
              v-model="candDatas.linkedinUrl"
            />
          </div>
          <div class="flex flex-col w-full">
            <input
              type="file"
              accept="application/pdf"
              class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
              placeholder="Upload CV"
              @change="onFileSelected"
            />
            <span
              v-if="this.candDatas.cv_name == ''"
              class="text-notif-orange text-xs italic text-left flex justify-start"
              >Upload your CV</span
            >
            <span
              v-if="this.candDatas.cv_name != ''"
              class="text-notif-orange text-xs italic text-left flex justify-start"
            >
              Current file: {{ this.candDatas.cv_name }}</span
            >
          </div>
        </div>

        <div class="w-full">
          <button
            @click="updateCandidate(candDatas.userID)"
            class="py-2 px-14 bg-custom-blue rounded-full flex justify-center items-center text-white"
          >
            <p class="text-white">Save</p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      candDatas: "",
      file: null,
      newImage: "",
      cvName: "",
    };
  },

  mounted() {
    // this.getCandidateUserID();
  },
  beforeMount() {
    this.getCandidateUserID();
  },

  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },

  methods: {
    showToast() {
      this.toast.success("Changes saved successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    previewImage(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newImage = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
        this.candDatas.profileImg = input.files[0];
      }
    },

    // at click div open input tag
    async imageInput() {
      document.getElementById("imageInput").click();
    },

    onFileSelected(event) {
      // first get file from candDatas

      if (event.target.files.length > 0) {
        this.candDatas.cv = event.target.files[0];
        this.cvName = event.target.files[0].name;
        this.candDatas.cv_name = event.target.files[0].name;
        console.log(this.candDatas.cv);
        console.log(event.target.files[0]);
      }

      this.file = event.target.files[0];
    },

    async updateCandidate() {
      if (this.newImage) {
        // reader = new FileReader();
        const reader = new FileReader();
        reader.readAsDataURL(this.candDatas.profileImg);
        reader.onload = () => {
          this.candDatas.profileImg = reader.result;

          console.log(this.candDatas.profileImg);
          console.log(this.userID);

          fetch(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/updateMentee/" +
              this.userID +
              "/",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                profileImg: this.candDatas.profileImg,
              }),
            }
          );
        };
      }

      const formData = new FormData();

      for (let key in this.candDatas) {
        if (this.candDatas[key] == null && key != "profileImg") {
          console.log(key, "is null");
          this.candDatas[key] = "";
        }
      }

      formData.append("firstName", this.candDatas.firstName);
      formData.append("lastName", this.candDatas.lastName);
      formData.append("phone", this.candDatas.phone);
      formData.append("email", this.candDatas.email);
      formData.append("linkedinUrl", this.candDatas.linkedinUrl);
      formData.append("cv_name", this.candDatas.cv_name);
      console.log(this.file);

      if (this.file != null) {
        console.log(this.file);
        formData.append("cv", this.file);
      }

      setTimeout(() => {
        axios
          .put(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/updateMentee/" +
              this.userID +
              "/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
              },
            }
          )
          .then((res) => {
            console.log(res);

            setTimeout(() => {
              this.showToast();
              location.reload();
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },

    async getCandidateUserID() {
      localStorage.getItem("userID")
        ? (this.userID = localStorage.getItem("userID"))
        : null;
      console.log(this.userID);
      this.getCandidateData(this.userID);
    },

    async getCandidateData(userID) {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/mentee/" +
            userID +
            "/"
        )
        .then((response) => {
          console.log(response);
          this.candDatas = response.data;
          // candidate cv name
          if (this.candDatas.cv == null) {
            this.cvName = "No CV uploaded";
          } else {
            this.cvName = this.candDatas.cv;
            console.log(this.cvName);
            this.file = this.candDatas.cv;
            console.log(this.candDatas.cv.name);
          }
          console.log(this.candDatas);
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
