<template>
  <body class="justify-center items-center overflow-hidden">
    <div
      :class="$route.path === '/' ? 'bg-light-blue' : 'bg-white'"
      class="pt-2 px-6 md:px-0"
    >
      <nav class="py-3 md:flex md:justify-between md:items-center md:px-32">
        <div class="flex items-center justify-between">
          <router-link to="/" class="text-blue-600 font-bold md:max-w-xs">
            <img
              class="w-1/4 h-1/4 md:w-3/6 md:h-3/6 lg:w-3/6 lg:h-3/6 xl:w-5/12 xl:h-5/12"
              src="../../public/hirelamp_logo_navbar.png"
              alt="Hirelamp Logo"
          /></router-link>
          <!-- Mobile menu button -->

          <!-- v-if="this.$store.state.userType === 'candidate'" -->
          <div
            v-if="showDashboardIcon"
            @click="showDashboard = !showDashboard && !showMenuMobile"
            class="flex md:hidden px-4"
          >
            <button
              type="button"
              class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
            >
              <img src="../assets/mobileDashboard.svg" alt="" class="w-9 h-9" />
            </button>
          </div>

          <div @click="showMenuMobile = !showMenuMobile" class="flex md:hidden">
            <button
              type="button"
              class="text-gray-800 hover:text-gray-400 focus:outline-none focus:text-gray-400"
            >
              <img src="../assets/navbarToggle.svg" alt="" class="w-9 h-9" />
            </button>
          </div>
        </div>

        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->

        <ul
          :class="showDashboard ? 'flex shadow-xl rounded-b-2xl' : 'hidden '"
          class="flex-col mt-8 space-y-2 md:align-middle md:space-y-0 md:items-center md:mt-0 md:px-0 text-sm lg:text-lg 2xl:text-lg flex md:hidden"
        >
          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                :to="`${newRoute}/aboutme`"
                @click="showDashboard = !showDashboard"
                class="text-gray-800 hover:text-custom-blue bt-navbar flex"
              >
                <img
                  src="../assets/aboutMe.svg"
                  alt=""
                  class="h-6 w-6 mr-4 align-middle"
                />

                About me</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                :to="`${newRoute}/sessions`"
                @click="showDashboard = !showDashboard"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar flex"
              >
                <img
                  src="../assets/dashboardSessions.svg"
                  alt=""
                  class="h-6 w-6 mr-4 align-middle"
                />
                Sessions</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                :to="`${newRoute}/security`"
                @click="showDashboard = !showDashboard"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar flex"
              >
                <img
                  src="../assets/dashboardSecurity.svg"
                  alt=""
                  class="h-6 w-6 mr-4 align-middle fill-custom-blue"
                />Security</router-link
              >
            </a>
          </li>
          <li class="nav-item text-left">
            <a
              class="nav-link text-gray-800 hover:text-custom-blue truncate bt-navbar flex"
              href="#"
              @click="
                handleSignOut();
                showDashboard = !showDashboard;
              "
              ><img
                src="../assets/logout.svg"
                alt=""
                class="h-6 w-6 mr-4 align-middle"
              />Logout
            </a>
          </li>
        </ul>

        <!-- navbar -->

        <ul
          v-if="hideMenuMobile"
          class="md:flex md:align-middle md:space-y-0 md:flex-row md:items-center md:mt-0 md:px-0 text-sm lg:text-lg 2xl:text-lg"
        >
          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                to="/"
                @click="showMenu = !showMenu"
                class="text-gray-800 hover:text-custom-blue bt-navbar"
                >Home</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                to="/mentors"
                @click="showMenu = !showMenu"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar"
                >Our mentors</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                to="/mbaclub"
                @click="showMenu = !showMenu"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar"
                >MBA Club</router-link
              >
            </a>
          </li>

          <!-- <li class="nav-item text-left" v-if="isLoggedIn == false">
            <div class="relative inline-block text-left">
              <a class="nav-link" href="#"
                ><p
                  @click="
                    showMenu = !showMenu;
                    registerOptions = !registerOptions;
                  "
                  class="text-gray-800 hover:text-custom-blue duration-200 hover:border-b-2 border-custom-blue truncate bt-navbar"
                >
                  Register
                </p>
              </a>
              <div
                v-if="registerOptions"
                class="bg-white shadow-lg rounded-md absolute flex flex-col right-0 z-10 mt-2 w-56 origin-top-right focus:ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <a class="nav-link" href="#"
                  ><router-link
                    to="/mentorRegister"
                    @click="
                      showMenu = !showMenu;
                      registerOptions = !registerOptions;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >Register as a mentor</router-link
                  >
                </a>
                <a class="nav-link" href="#"
                  ><router-link
                    to="/signup"
                    @click="
                      showMenu = !showMenu;
                      registerOptions = !registerOptions;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >Register as a mentee</router-link
                  >
                </a>
              </div>
            </div>
          </li> -->

          <li class="nav-item text-left" v-if="isLoggedIn == false">
            <div class="relative inline-block text-left">
              <span class="nav-link pr-0">
                <!-- 
                  @focus="loginOptions = !loginOptions"
                   @focusout="loginOptions =!loginOptions" 

                -->
                <button
                  @click="loginOptions = !loginOptions"
                  @blur="removeIt()"
                  tabindex="0"
                  class="text-custom-blue duration-200 hover:border-b-2 border-custom-blue bt-fontonly"
                >
                  Login
                </button>
              </span>
              <div
                v-if="loginOptions"
                class="bg-white shadow-lg justify-center text-center rounded-md absolute flex flex-col right-0 z-10 mt-2 w-36 origin-top-right ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <a class="nav-link" href="#"
                  ><router-link
                    to="/login"
                    @click="
                      loginOptions = !loginOptions;
                      showMenu = !showMenu;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >As candidate</router-link
                  >
                </a>
                <a class="nav-link" href="#"
                  ><router-link
                    to="/mentorLogin"
                    @click="
                      loginOptions = !loginOptions;
                      showMenu = !showMenu;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >As mentor</router-link
                  >
                </a>
              </div>
            </div>
          </li>
          <!-- <div class="outside" v-on:click="removeIt()"></div> -->

          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="relative inline-block text-left">
            <div class="w-full pl-3">
              <button
                v-if="isLoggedIn"
                @click="
                  showMenu = !showMenu;
                  navigateToDashboard();
                "
                type="button"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar flex flex-row justify-center align-middle content-center items-center"
              >
                <!-- <img class="h-10 w-10 rounded-full" :src="profileImg" alt="" />
                <span class="pl-2 pt-1">{{ this.fullName }}</span> -->
                <p>Profile</p>
              </button>
            </div>
          </div>
        </ul>

        <ul
          :class="showMenuMobile ? 'flex shadow-xl rounded-b-2xl' : 'hidden '"
          class="flex-col mt-8 space-y-2 shadow-md rounded-b-2xl text-sm"
        >
          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                to="/"
                @click="showMenuMobile = !showMenuMobile"
                class="text-gray-800 hover:text-custom-blue bt-navbar"
                >Home</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                to="/mentors"
                @click="showMenuMobile = !showMenuMobile"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar"
                >Our mentors</router-link
              >
            </a>
          </li>

          <li class="nav-item text-left">
            <a class="nav-link" href="#"
              ><router-link
                to="/mbaclub"
                @click="showMenuMobile = !showMenuMobile"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar"
                >MBA Club</router-link
              >
            </a>
          </li>

          <!-- <li class="nav-item text-left" v-if="isLoggedIn == false">
            <div class="relative inline-block text-left">
              <a class="nav-link" href="#"
                ><p
                  @click="
                    showMenuMobile = !showMenuMobile;
                    registerOptions = !registerOptions;
                  "
                  class="text-gray-800 hover:text-custom-blue duration-200 hover:border-b-2 border-custom-blue truncate bt-navbar"
                >
                  Register
                </p>
              </a>
              <div
                v-if="registerOptions"
                class="bg-white shadow-lg rounded-md absolute flex flex-col right-0 z-10 mt-2 w-56 origin-top-right focus:ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <a class="nav-link" href="#"
                  ><router-link
                    to="/mentorRegister"
                    @click="
                      showMenu = !showMenu;
                      registerOptions = !registerOptions;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >Register as a mentor</router-link
                  >
                </a>
                <a class="nav-link" href="#"
                  ><router-link
                    to="/signup"
                    @click="
                      showMenu = !showMenu;
                      registerOptions = !registerOptions;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >Register as a mentee</router-link
                  >
                </a>
              </div>
            </div>
          </li> -->
          <!-- 
          <li class="nav-item text-left" v-if="isLoggedIn == false">
            <div class="relative inline-block text-left">
              <span class="nav-link pr-0">
            
                <button
                  @focus="loginOptions = !loginOptions"
                  @blur="removeIt()"
                  tabindex="0"
                  class="text-custom-blue duration-200 hover:border-b-2 border-custom-blue bt-fontonly"
                >
                  Login
                </button>
              </span>
              <div
                v-if="loginOptions"
                class="bg-white shadow-lg justify-center text-center rounded-md absolute flex flex-col right-0 z-10 mt-2 w-36 origin-top-right ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <a class="nav-link" href="#"
                  ><router-link
                    to="/login"
                    @click="
                      loginOptions = !loginOptions;
                      showMenu = !showMenu;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >Mentee</router-link
                  >
                </a>
                <a class="nav-link" href="#"
                  ><router-link
                    to="/mentorLogin"
                    @click="
                      loginOptions = !loginOptions;
                      showMenu = !showMenu;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >Mentor</router-link
                  >
                </a>
              </div>
            </div>
          </li> -->

          <li class="nav-item text-left" v-if="isLoggedIn == false">
            <div class="relative inline-block text-left">
              <button class="nav-link pr-0">
                <p
                  @click="loginOptions = !loginOptions"
                  @blur="removeIt()"
                  tabindex="0"
                  class="text-custom-blue truncate bt-navbar duration-200 hover:border-b-2 border-custom-blue"
                >
                  Login
                </p>
              </button>
              <div
                v-if="loginOptions"
                class="bg-white shadow-lg justify-center text-left rounded-md absolute flex flex-col left-0 z-10 mt-2 w-fit origin-top-left ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <a class="nav-link" href="#"
                  ><router-link
                    to="/login"
                    @click="
                      showMenuMobile = !showMenuMobile;
                      loginOptions = !loginOptions;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >As candidate</router-link
                  >
                </a>
                <a class="nav-link" href="#"
                  ><router-link
                    to="/mentorLogin"
                    @click="
                      showMenuMobile = !showMenuMobile;
                      loginOptions = !loginOptions;
                    "
                    class="text-gray-800 hover:text-custom-blue truncate bt-navbar px-2 py-2"
                    >As mentor</router-link
                  >
                </a>
              </div>
            </div>
          </li>

          <!-- This example requires Tailwind CSS v2.0+ -->
          <div
            v-if="isLoggedIn == true"
            class="relative inline-block text-left px-3 pb-3"
          >
            <div class="w-full">
              <button
                v-if="isLoggedIn"
                @click="
                  showMenuMobile = !showMenuMobile;
                  navigateToDashboard();
                "
                type="button"
                class="text-gray-800 hover:text-custom-blue truncate bt-navbar flex flex-row justify-center align-middle content-center items-center"
              >
                <img class="h-10 mr-4 rounded-full" :src="profileImg" alt="" />

                <!-- <p v-if="this.fullname !== null">
                  {{ this.fullName }}
                </p>
                <p v-if="this.fullname == null">Profile</p> -->
                <p>Profile</p>
              </button>
            </div>
          </div>
        </ul>
      </nav>
    </div>
    <router-view :key="$route.fullPath" />
  </body>
</template>
<script>
// import firebase from "firebase/app";
import firebase from "../utilities/firebase";
import axios from "axios";
export default {
  props: { isLoggedIn: Boolean },
  data() {
    return {
      showMenu: false,
      // fullName: this.fullName,
      showDropdown: false,
      candData: [],
      profileImg: this.profileImg,
      showDashboard: false,
      showMenuMobile: false,
      hideMenuMobile: false,
      showDashboardIcon: false,
      userID: this.userID,
      loginOptions: false,
      registerOptions: false,
    };
  },

  watch: {
    $route(to) {
      if (
        to.path == "/dashboard/aboutme" ||
        to.path == "/dashboard/sessions" ||
        to.path == "/dashboard/security" ||
        to.path == "/mentorDashboard/aboutme" ||
        to.path == "/mentorDashboard/sessions" ||
        to.path == "/mentorDashboard/security" ||
        to.path == "/mentorFeedback"
      ) {
        this.showDashboardIcon = true;
      } else {
        this.showDashboardIcon = false;
      }
    },
  },

  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.getCandidateUserID();
    this.handleResize();
  },
  methods: {
    removeIt() {
      setTimeout(() => {
        this.loginOptions = false;
      }, 150);
      console.log("removeIt");
    },

    async getCandidateUserID() {
      localStorage.getItem("userID");
      this.userID = localStorage.getItem("userID");
      localStorage.getItem("isMentor");

      this.isMentor = localStorage.getItem("isMentor");

      if (this.isMentor == "true") {
        this.newRoute = "/mentorDashboard";
      } else if (this.isMentor == "false") {
        this.newRoute = "/dashboard";
      }
    },

    isLoggedInFunction() {
      if (localStorage.getItem("fullName") != null) {
        this.fullName = localStorage.getItem("fullName");
        this.usernameDisplayed = true;
        localStorage.setItem("usernameDisplayed", this.usernameDisplayed);
        this.mentorID = localStorage.getItem("userID");
        axios
          .get(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/expert/profile/" +
              this.mentorID
          )
          .then((response) => {
            this.profileImg = response.data.profileImg;
          });
      }
    },
    //if showMenu is open then close it when the screen is resized
    handleResize() {
      if (window.innerWidth <= 768) {
        this.showMenuMobile = false;
        this.showMenu = false;
      } else {
        this.showMenuMobile = false;
        this.hideMenuMobile = true;
      }
      //prevent opening the menu and dashboard at the same time
      if (this.showDashboard == true) {
        this.showMenuMobile = false;
      }
    },
    // if the page is dashboard then show the dashboard menu
    showDashboardMenu() {
      if (
        this.$route.path == "/dashboard/aboutme" ||
        this.$route.path == "/mentorDashboard/aboutme"
      ) {
        this.showDashboard = true;
        this.showDashboardIcon = true;
      } else {
        this.showDashboard = false;
        this.showDashboardIcon = false;
      }
    },

    navigateToDashboard() {
      // check if isMentor true then send to mentor dashboard else send to candidate dashboard
      localStorage.getItem("isMentor") == "true"
        ? this.$router.push("/mentorDashboard/aboutme")
        : this.$router.push("/dashboard/aboutme");
    },

    displayUsername() {
      //if the current page is MentorPage then show the user name
      if (this.isLoggedIn == true) {
        localStorage.getItem("fullName")
          ? (this.fullName = localStorage.getItem("fullName"))
          : null;
      }
    },
    handleSignOut() {
      if (this.isLoggedIn) {
        firebase
          .auth()
          .signOut()
          .then(() => {
            localStorage.removeItem("userEmail");
            localStorage.removeItem("fullName");
            localStorage.removeItem("userID");
            localStorage.removeItem("isMentor");
            localStorage.removeItem("userFullname");

            this.$router.push("/");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
.outside {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1;
}
</style>
