<template>
  <div
    class="w-full py-12 md:py-18 flex justify-center items-center align-middle px-6 md:px-32"
  >
    <div
      class="flex flex-col md:flex-row w-full justify-center align-middle items-center"
    >
      <div class="md:w-1/2 flex flex-col justify-start text-left space-y-4">
        <h1 class="bt-fontonly font-bold text-xl md:text-7xl text-custom-blue">
          Boom!
        </h1>
        <h1 class="bt-fontonly text-md md:text-2xl">
          Your payment has been successfully processed and your purchase is a
          done deal.
        </h1>
        <h1 class="bt-fontonly text-sm md:text-xl text-custom-gray py-8">
          High-five for choosing our services. You rock! Keep being awesome, and
          we'll catch you on the flip side.
        </h1>
      </div>
      <div class="flex justify-center h-0 w-0 md:h-full md:w-1/2">
        <lottie-player
          src="https://lottie.host/f3607ba1-8c93-4906-9d13-230108ba32eb/U15Hm0sgvY.json"
          background="transparent"
          speed="1"
          style="width: 500px; height: 500px"
          loop
          autoplay
        ></lottie-player>
      </div>
      <div class="flex justify-center h-full w-full md:w-0 md:h-0">
        <lottie-player
          src="https://lottie.host/f3607ba1-8c93-4906-9d13-230108ba32eb/U15Hm0sgvY.json"
          background="transparent"
          speed="1"
          style="width: 300px; height: 300px"
          loop
          autoplay
        ></lottie-player>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import "@lottiefiles/lottie-player";
import Footer from "../components/Footer.vue";
import axios from "axios";
import dataLayer from "../../src/utilities/dataLayer.js";
export default {
  components: { Footer },
  data() {
    return {
      paymentId: "",
      mentorName: "",
      sessionTopic: "",
      price: "",
      paymentData: "",
    };
  },

  mounted() {
    this.successfullPayment();
  },
  methods: {
    trackEcommercePurchase() {
      const transactionId = this.paymentId; // Replace with your actual transaction ID
      //const affiliation = "Example Store"; // Replace with your actual store name
      const revenue = this.price; // Replace with your actual revenue
      //const tax = 10.0; // Replace with your actual tax amount
      //const shipping = 5.0; // Replace with your actual shipping amount
      const items = [
        {
          item_id: this.paymentId,
          item_name: this.mentorName,
          //affiliation: affiliation,
          // coupon: "SUMMER_FUN",
          // discount: 2.22,
          index: 0,
          item_brand: "Session",
          item_category: this.sessionTopic,
          item_category2: this.mentorName,
          price: this.price,
          quantity: 1,
        },
        // Add more products if needed
      ];
      console.log(transactionId);
      //console.log(affiliation);
      console.log(revenue);
      //console.log(tax);
      //console.log(shipping);
      console.log(items);

      dataLayer.pushPurchaseEvent(
        transactionId,
        //affiliation,
        revenue,
        //tax,
        //shipping,
        items
      );
    },
    successfullPayment() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/payment/success"
        )
        .then((response) => {
          console.log(response);
          this.paymentData = response.data.order_details;
          this.paymentId = response.data.res_payment.bankSessionId;
          this.price = this.paymentData.price;
          this.paymentStatus = this.paymentData.paymentStatus;
          this.mentorName = this.paymentData.mentor_name;
          this.paymentDate = this.paymentData.date;
          this.sessionTopic = this.paymentData.topic;
          console.log(this.paymentData);
          console.log(this.price);
          console.log(this.paymentId);
          this.trackEcommercePurchase();
        });
    },
  },
};
</script>

<style></style>
