<template>
  <div class="flex w-full pb-8 justify-between">
    <span class="flex flex-col space-y-4">
      <small class="text-gray-500">These settings will be valid:</small>

      <select
        class="bg-custom-blue text-white rounded-xl px-1.5 py-2"
        name=""
        id=""
        v-model="tillSettings"
      >
        <!-- <option v-for="timezone in timezones" :value="timezone" :key="timezone">{{ timezone }}</option> -->
        <option default value="indefinite">Till I change</option>
        <option value="1week">For 1 week</option>
        <option value="1month">For 1 month</option>
        <option value="6months">For 6 months</option>
      </select>
    </span>
    <p>
      Your timezone is <span class="font-bold">{{ this.mentorTimezone }}</span>
    </p>
  </div>
  <table class="table-auto w-full border-2 justify-center">
    <thead>
      <tr class="bg-gray-100 divide-x-2">
        <th class="px-4 py-2 text-center w-20">Days</th>
        <th class="px-4 py-2 text-center">Time Slots</th>
        <th class="px-4 py-2 text-center w-36">Action</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="day in weekdays" :key="day" class="border-2 divide-x-2">
        <td class="px-4 py-2 text-center">{{ day }}</td>
        <td class="px-4 py-2 flex">
          <!-- :class="{
            'justify-between': showDropdown[day] === true,
            'justify-end': showDropdown[day] != null,
          }" -->
          <div
            v-for="(time, index) in times[day]"
            :key="index"
            class="flex items-center justify-start"
          >
            <span class="space-x-2 flex items-center justify-start">
              <span class="text-gray-700 border-2 rounded-md px-2 py-1.5">
                {{ time.from }}
                -
                {{ time.till }}
              </span>
              <!-- </span> -->
              <!-- <span class="space-x-2 flex items-center justify-start">
              <span class="text-gray-700 border-2 rounded-md p-2">
                {{ time.from }}
              </span>
              <span class="text-gray-700 border-2 rounded-md p-2">{{
                time.till
              }}</span> -->
            </span>
            <button
              @click="removeTime(day, index)"
              class="text-white bg-red-500 rounded-full px-2 mx-1"
            >
              -
            </button>
          </div>
          <!-- <div class="text-right flex justify-between"> -->
          <div v-show="showDropdown[day]" class="flex justify-start">
            <select v-model="from" class="bg-white border rounded px-2 py-1.5">
              <option v-for="time in timesList" :value="time" :key="time">
                {{ time }}
              </option>
            </select>
            <select v-model="till" class="bg-white border rounded px-2 py-1.5">
              <option v-for="time in timesList" :value="time" :key="time">
                {{ time }}
              </option>
            </select>
            <span class="px-4 space-x-4">
              <button
                @click="addCustomTime(day)"
                class="bg-blue-500 text-white px-2 py-1.5 rounded"
              >
                Save
              </button>
              <button
                @click="showDropdown[day] = false"
                class="bg-red-500 text-white px-2 py-1.5 rounded"
              >
                Cancel
              </button></span
            >
          </div>
          <!-- <div class="flex justify-end">
            <button
              @click="addTime(day)"
              class="bg-green-500 text-white p-2 rounded justify-end flex"
            >
              Add Time
            </button>
          </div> -->

          <!-- </div> -->
        </td>
        <td class="px-4 py-2 text-center">
          <div class="flex justify-end">
            <button
              @click="addTime(day)"
              class="bg-green-500 text-white p-2 rounded justify-end flex"
            >
              Add Time
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="w-full flex justify-end pt-8">
    <button
      @click="sendOption()"
      class="py-2 px-14 bg-custom-blue rounded-full flex justify-center items-center text-white"
    >
      <p class="text-white">Continue</p>
    </button>
  </div>
  <!-- </div> -->
</template>

<script>
import moment from "moment-timezone";
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
  props: ["oldAvailableSlots", "tillSettingsBackend", "mentorID"],
  data() {
    return {
      weekdays: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
      times: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
      showDropdown: {
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      },
      timesList: [
        "9:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
      ],
      from: "",
      till: "",
      selectedCheckbox: [],
      availableTimes: [],
      mentorTimezone: "",
      tillSettings: "",
      removedTime: "",
      dayTime: "",
      oldAvailableSlotsNew: [],
      removedTimeArray: [],
      // oldAvailableSlotsNewArray: [],
    };
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  mounted() {
    this.mentorTimezoneFunc();
    this.atStart();
  },
  methods: {
    mentorTimezoneFunc() {
      let mentorTimezone = moment.tz.guess();
      console.log(mentorTimezone);
      this.mentorTimezone = mentorTimezone;
      return mentorTimezone;
    },
    addTime(day) {
      this.showDropdown[day] = true;
    },
    atStart() {
      console.log(this.tillSettingsBackend);
      this.tillSettings = this.tillSettingsBackend;
      console.log(this.tillSettings);
      console.log(this.oldAvailableSlots);
      if (this.oldAvailableSlots) {
        let oldAvailableSlots = this.oldAvailableSlots;
        this.oldAvailableSlotsNew = oldAvailableSlots;
        console.log(this.oldAvailableSlotsNew);
        let oldAvailableSlotsArray = oldAvailableSlots.split(";");
        oldAvailableSlotsArray.forEach((element) => {
          let day = element.split("|")[0];
          for (let i = 0; i < this.weekdays.length; i++) {
            if (this.weekdays[i].slice(0, 3) === day) {
              day = this.weekdays[i];
              let times = element.split("|")[1];
              console.log(times);
              // divide before comma and after comma as from and till
              let timesArray = times.split(",");
              if (timesArray.length === 2) {
                let from = timesArray[0];
                let till = timesArray[1];
                let newTime = { from: from + ":00", till: till + ":00" };
                this.times[day].push(newTime);
              }
            }
          }
        });
      }
    },
    addCustomTime(day) {
      let fromMinutes = this.from.split(":")[1].slice(0, 2);
      let tillMinutes = this.till.split(":")[1].slice(0, 2);
      if (fromMinutes !== "00" && fromMinutes !== "30") {
        alert("Invalid format of minutes, it should be :00 or :30");
        return;
      }
      if (tillMinutes !== "00" && tillMinutes !== "30") {
        alert("Invalid format of minutes, it should be :00 or :30");
        return;
      }
      let newTime = { from: this.from, till: this.till };
      this.times[day].push(newTime);
      let shortDays = day.slice(0, 3);
      let from = this.from.split(":")[0];
      let till = this.till.split(":")[0];
      let dayTime = shortDays + "|" + from + "," + till;
      this.dayTime = dayTime;
      this.availableTimes.push(dayTime);
      console.log(this.availableTimes);
      console.log(this.times);
      this.showDropdown[day] = false;
      this.from = "";
      this.till = "";
    },
    removeTime(day, index) {
      this.removedTime = this.times[day][index];
      console.log(this.removedTime);
      this.times[day].splice(index, 1);
      let shortDays = day.slice(0, 3);
      let from = this.removedTime.from.split(":")[0];
      let till = this.removedTime.till.split(":")[0];
      this.removedTime = shortDays + "|" + from + "," + till;
      console.log(this.removedTime);
      this.removedTimeArray.push(this.removedTime);
      console.log(this.removedTimeArray);
      this.oldAvailableSlotsNew = this.oldAvailableSlots;
      this.removedTimeArray.forEach((element) => {
        this.oldAvailableSlotsNew = this.oldAvailableSlotsNew.replace(
          element + ";",
          ""
        );
        console.log(this.element);
      });
      console.log(this.oldAvailableSlotsNew);
    },
    showToast() {
      this.toast.warning("Click 'Save' to save your changes!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
    //send the option to the parent component
    //send selected option to the parent component
    sendOption() {
      this.showToast();
      console.log(this.removedTime);
      console.log(this.dayTime);
      // this.availableTimes = "" + this.dayTime + ",";
      console.log(this.availableTimes);
      this.availableTimes = this.availableTimes.concat(
        this.oldAvailableSlotsNew
      );
      console.log(this.availableTimes);
      this.availableTimes = this.availableTimes.toString();
      console.log(this.availableTimes);
      console.log(this.tillSettings);
      // this.mentorTimezone,
      //       this.tillSetting
      this.$emit("sendOption", this.availableTimes);
      this.$emit("sendTillSettings", this.tillSettings);
      // this.$emit("sendOption", this.availableTimes);
      this.$emit("close");
      let formData = new FormData();
      formData.append("availability", this.availableTimes);
      formData.append("availabilityDuration", this.tillSettings);
      setTimeout(() => {
        axios
          .put(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/updateMentor/" +
              this.mentorID +
              "/",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            console.log(res);
            // this.showToast();
            // location.reload();
          })
          .catch((err) => {
            console.log(err);
          });
      }, 1000);
    },
    //get the values of the selected options
    getSelectedOptions() {
      return this.options.filter((option) => option.selected);
    },
    addNewRow() {
      this.options.push({ label: "Monday", value: "Monday" });
    },
  },
};
</script>

<style></style>
