<template>
  <body class="bt-fontonly">
    <div class="relative h-72 md:h-96 overflow-hidden">
      <img
        src="../assets/testLanding.png"
        alt="Landing Page Image"
        class="object-cover w-0 md:w-full md:px-32"
      />

      <div
        class="md:absolute h-full w-full py-2.5 bottom-0 inset-x-0 text-white text-left px-6 md:px-32 space-y-8 flex md:align-middle md:justify-center flex-col"
      >
        <span class="flex sm:w-28 space-x-4">
          <img src="../assets/mba_blue.png" alt="MBA Club" class="w-auto h-8" />
          <!-- <span class="text-black font-extrabold text-md">X</span> -->
          <img
            src="../../public/hirelamp_logo_navbar.png"
            alt="Hirelamp"
            class="w-auto h-8"
          />
        </span>
        <p class="text-black text-xl md:text-3xl w-96 font-extralight">
          Apply to <span class="font-bold"> top MBA programs </span> with the
          assistance of experienced mentors
        </p>
        <p class="text-black text-sm w-64 md:w-96">
          Welcome to our Top School MBA Application Mentorship Services
        </p>
        <button
          @click="scrollToBottom"
          class="bg-black text-white px-4 py-1.5 rounded-md w-24"
        >
          Apply
        </button>
      </div>
    </div>

    <div class="md:px-32">
      <p class="py-8 text-xl text-gray-500">
        Our mentors will help you to prepare MBA programs from:
      </p>

      <div class="auto-slider">
        <div class="slider-container" ref="sliderContainer">
          <div class="slider-track" ref="sliderTrack">
            <div
              v-for="(image, index) in imagesWithDuplicates"
              :key="index"
              class="slider-item"
              :style="{ animationDelay: `${index * 5}s` }"
            >
              <img :src="image.src" :alt="image.alt" class="slider-image" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 md:px-32 py-4 md:py-24">
      <div
        class="pt-11 md:w-auto xl:w-auto mx-auto flex items-stretch justify-between flex-col md:flex-row col-end-1 md:space-x-8 pb-24"
      >
        <div class="w-full xl:mb-0 relative md:flex flex-col">
          <div class="text-left">
            <p class="font-bold text-2xl pb-12">
              Unlock the power of diverse network
            </p>
          </div>
          <div class="text-left text-lg">
            <p class="pb-8 text-gray-500">
              If you are not only dreaming about elite MBA schools, but have
              taken the first step to apply, then you are in the right place.
            </p>
            <p class="pb-8 text-gray-500">
              MBA Club Mentorship program is network of alumni and students of
              top global MBAs who are passionate about helping others also to
              succeed. So far our mentors have helped more than 20 applicants
              from Azerbaijan to get admitted to their dream schools.
            </p>
            <p class="pb-8 text-gray-500">
              The mentorship program is free and provides personal guidance and
              support through application and interview stages.
            </p>
            <p class="pb-8 text-gray-500">
              Take first step toward your dream with apply to MBA mentorship
              program!
            </p>
          </div>
        </div>
        <div
          class="w-full md:w-11/12 flex flex-col justify-center align-middle items-center"
        >
          <imageSlider />
        </div>
      </div>

      <div class="shadow-xl rounded-b-2xl rounded-tl-2xl w-full px-4 bg-white">
        <p class="py-6">Fill the form to apply</p>
        <div class="flex flex-col">
          <form action="" @submit.prevent="sendForm()">
            <div class="pb-4">
              <div
                class="flex flex-col md:flex-row md:space-x-8 justify-between"
              >
                <span class="w-full pb-4 md:pb-0">
                  <div class="relative">
                    <input
                      v-model="fullName"
                      type="text"
                      class="block border-1 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                      placeholder="Fullname"
                      required
                    />
                  </div>

                  <div class="py-2"></div>
                  <div class="relative">
                    <input
                      v-model="email"
                      type="email"
                      class="block border-1 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                      placeholder="Email"
                      required
                    />
                  </div>

                  <div class="py-2"></div>
                  <div class="relative">
                    <div class="flex flex-col w-full">
                      <input
                        type="file"
                        accept="application/pdf"
                        class="block border-1 p-2.5 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                        placeholder="Upload CV"
                        @change="onFileSelected"
                        required
                      />
                      <span
                        v-if="this.cv == null"
                        class="text-notif-orange text-xs italic text-left flex justify-start"
                        >Upload your CV</span
                      >
                      <span
                        v-if="this.cv != null"
                        class="text-notif-orange text-xs italic text-left flex justify-start"
                      >
                        Current file: {{ this.cvName }}</span
                      >
                    </div>
                  </div>
                </span>
                <span class="w-full">
                  <div class="relative">
                    <div class="">
                      <div class="flex flex-col w-full">
                        <button
                          id="dropdownButton"
                          @click="this.dropdown = !this.dropdown"
                          @blur="removeIt()"
                          tabindex="0"
                          class="flex justify-between text-gray-900 bg-white border-1 rounded-2xl text-sm px-6 p-3.5 w-full"
                          type="button"
                        >
                          <!-- <span
                            class="text-gray-400"
                            v-if="selectedSchools != ''"
                            >Update your school(s)</span
                          > -->

                          <span
                            class="text-gray-400 flex"
                            v-if="selectedSchools != ''"
                          >
                            <ul
                              class="flex flex-row"
                              v-for="selectedSchool in selectedSchools"
                              :key="selectedSchool"
                            >
                              <li class="flex flex-row">
                                <span
                                  class="truncate overflow-hidden w-16 md:w-24"
                                >
                                  {{ selectedSchool.name }}

                                  <span>, </span>
                                </span>
                              </li>
                            </ul>
                          </span>
                          <span
                            class="text-gray-400"
                            v-if="selectedSchools == ''"
                          >
                            Select at least one and up to three schools
                          </span>
                          <span>
                            <svg
                              class="w-4 h-4"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M19 9l-7 7-7-7"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      </div>
                      <div
                        v-if="this.dropdown == true"
                        id="dropdown"
                        class="absolute z-10 w-full bg-white rounded-b-2xl divide-y divide-gray-100 shadow"
                      >
                        <ul
                          id="dropdown"
                          class="p-3 text-sm text-gray-700 dark:text-gray-200 overflow-y-auto max-h-52"
                        >
                          <li>
                            <div
                              v-for="school in schools"
                              :key="school"
                              class="flex items-center"
                            >
                              <input
                                type="checkbox"
                                placegolder="Services"
                                v-model="selectedSchools"
                                :value="school"
                                :disabled="
                                  selectedSchools.length >= 3 &&
                                  !selectedSchools.find(
                                    (selectedSchool) =>
                                      selectedSchool.name === school.name
                                  )
                                "
                                class="text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500"
                              />
                              <label
                                for="checkbox-item-1"
                                class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                <span> {{ school.name }} </span>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div class="py-2"></div>

                  <div class="relative">
                    <input
                      v-model="linkedinURL"
                      type="text"
                      class="block border-1 p-2.5 px-6 w-full h-12 z-20 text-sm text-gray-900 bg-white rounded-2xl"
                      placeholder="Linkedin URL"
                      required
                    />
                  </div>
                  <div class="py-2"></div>

                  <div class="relative">
                    <div class="flex flex-col w-full">
                      <input
                        type="file"
                        accept="application/pdf, image/*, "
                        class="border w-full border-gray-700 bg-white text-gray-400 rounded-2xl p-2"
                        placeholder="Upload GMAT or GRE score"
                        @change="onFileSelectedGMAT"
                        required
                      />
                      <span
                        v-if="this.GMATorGRE == null"
                        class="text-notif-orange text-xs italic text-left flex justify-start"
                        >Upload GMAT or GRE score</span
                      >
                      <span
                        v-if="this.GMATorGRE != null"
                        class="text-notif-orange text-xs italic text-left flex justify-start"
                      >
                        Current file: {{ this.GMATorGREname }}</span
                      >
                    </div>
                  </div>
                </span>
              </div>
              <div class="py-2"></div>
              <button
                type="submit"
                class="h-11 w-48 rounded-2xl text-gray-50 bg-black hover:shadow-lg duration-300 px-2 py-1.5"
              >
                <p>Submit</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- <Footer /> -->
  </body>
</template>

<script>
import imageSlider from "../components/imageSlider.vue";
import axios from "axios";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
//import Footer from "../components/Footer.vue";
// import "@splidejs/vue-splide/css/skyblue";
export default {
  name: "MentorRegister",
  data() {
    return {
      toastMessage: "",
      toastColor: "",
      fullName: "",
      email: "",
      interests: "",
      loading: false,
      selectedSchools: [],
      dropdown: false,
      GMATorGRE: null,
      phoneNumber: "",
      linkedinURL: "",
      jsonSelectedSchools: [],
      images: [
        {
          id: 1,
          src: require("@/assets/uni_logos/harvard.png"),
          alt: "Image 1",
        },
        {
          id: 2,
          src: require("@/assets/uni_logos/stanford.png"),
          alt: "Image 2",
        },
        {
          id: 3,
          src: require("@/assets/uni_logos/wharton.png"),
          alt: "Image 3",
        },
        {
          id: 4,
          src: require("@/assets/uni_logos/booth.png"),
          alt: "Image 4",
        },
        {
          id: 5,
          src: require("@/assets/uni_logos/kellogg.png"),
          alt: "Image 5",
        },
        {
          id: 6,
          src: require("@/assets/uni_logos/columbia.png"),
          alt: "Image 6",
        },
        {
          id: 7,
          src: require("@/assets/uni_logos/cornell.png"),
          alt: "Image 7",
        },
        {
          id: 8,
          src: require("@/assets/uni_logos/anderson.png"),
          alt: "Image 8",
        },
        {
          id: 9,
          src: require("@/assets/uni_logos/darden.png"),
          alt: "Image 9",
        },
        {
          id: 10,
          src: require("@/assets/uni_logos/fuqua.png"),
          alt: "Image 10",
        },
        {
          id: 11,
          src: require("@/assets/uni_logos/georgetown.png"),
          alt: "Image 11",
        },
        {
          id: 12,
          src: require("@/assets/uni_logos/haas.png"),
          alt: "Image 12",
        },
        {
          id: 13,
          src: require("@/assets/uni_logos/imd.png"),
          alt: "Image 13",
        },
        {
          id: 14,
          src: require("@/assets/uni_logos/insead.png"),
          alt: "Image 14",
        },
        {
          id: 15,
          src: require("@/assets/uni_logos/ivey.png"),
          alt: "Image 15",
        },
        {
          id: 16,
          src: require("@/assets/uni_logos/lbs.png"),
          alt: "Image 16",
        },
        {
          id: 17,
          src: require("@/assets/uni_logos/mccombs.png"),
          alt: "Image 17",
        },
        {
          id: 18,
          src: require("@/assets/uni_logos/mit.png"),
          alt: "Image 18",
        },
        {
          id: 19,
          src: require("@/assets/uni_logos/ross.png"),
          alt: "Image 19",
        },
        {
          id: 20,
          src: require("@/assets/uni_logos/rotman.png"),
          alt: "Image 20",
        },
        {
          id: 21,
          src: require("@/assets/uni_logos/stern.png"),
          alt: "Image 21",
        },
        {
          id: 22,
          src: require("@/assets/uni_logos/tuck.png"),
          alt: "Image 22",
        },
        {
          id: 23,
          src: require("@/assets/uni_logos/yale.png"),
          alt: "Image 23",
        },
        // Add more images as needed
      ],
      visibleImages: [],
      currentImageIndex: 0,
      autoScrollInterval: null,
      slide: 0, // Current slide position
      slideInterval: null,
      transitionInProgress: false,
      schoolOptions: false,
      cv: null,
      cvName: "",
      GMATorGREname: "",
    };
  },
  setup() {
    const toast = useToast();
    const router = useRouter();

    const role = ref("");

    return {
      role,
      toast,
      router,
    };
  },

  components: {
    //  Footer,
    imageSlider,
  },

  mounted() {
    this.startSlideInterval();
    this.getSchools();
  },
  beforeUnmount() {
    this.stopSlideInterval();
  },
  computed: {
    imagesWithDuplicates() {
      return [...this.images, ...this.images]; // Duplicate images to create a loop effect
    },
  },
  methods: {
    onFileSelected(event) {
      // first get file from candDatas

      if (event.target.files.length > 0) {
        this.cv = event.target.files[0];
        this.cvName = event.target.files[0].name;
      }
    },
    onFileSelectedGMAT(event) {
      // first get file from candDatas

      if (event.target.files.length > 0) {
        this.GMATorGRE = event.target.files[0];
        this.GMATorGREname = event.target.files[0].name;
      }
    },

    removeIt() {
      setTimeout(() => {
        // if the user clicks outside the dropdown button or the dropdown itself, then close the dropdown
        window.addEventListener("click", (e) => {
          const dropdownButton = document.getElementById("dropdownButton");
          const dropdown = document.getElementById("dropdown");
          if (
            dropdownButton &&
            dropdown &&
            !dropdownButton.contains(e.target) &&
            !dropdown.contains(e.target)
          ) {
            this.dropdown = false;
          }
        });
      }, 150);
      console.log("removeIt");
      console.log(this.dropdown);
    },

    // scroll to bottom of page but not to footer
    scrollToBottom() {
      window.scrollTo(0, document.body.scrollHeight - 100);
    },

    startSlideInterval() {
      this.slideInterval = setInterval(this.slideNext, 5000); // Slide every 5 seconds
    },
    stopSlideInterval() {
      clearInterval(this.slideInterval);
      this.slideInterval = null;
    },
    slideNext() {
      const slideWidth = this.getSlideWidth();
      this.slide += slideWidth;

      if (this.slide >= slideWidth * this.images.length) {
        this.slide = 0;
      }
    },
    getSlideWidth() {
      const containerWidth = this.$refs.sliderContainer.offsetWidth;
      const slideWidth = containerWidth / this.images.length;

      return slideWidth;
    },
    showErrorToast(message) {
      this.toast.error(message, {
        position: "top-right",
        duration: 3000,
        action: {
          text: "Dismiss",
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },

    showToast() {
      this.toast.success("Thanks! Your application has been received", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    },

    dropDownFunction() {
      this.dropDown = !this.dropDown;
    },

    // get the list of schools from the API
    getSchools() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/highschools/"
        )
        .then((response) => {
          this.schools = response.data;
          console.log(this.schools);
        })
        .catch((error) => {
          this.showErrorToast(error.message);
        });
    },

    sendForm() {
      this.selectedSchools = this.selectedSchools.map((school) => school.id);
      console.log(this.selectedSchools);
      this.jsonSelectedSchools = JSON.stringify(this.selectedSchools);
      console.log(this.jsonSelectedSchools);
      // console log only ids of selected schools to send to API

      if (this.fullName == "" && this.email == "" && this.interests == "") {
        this.showErrorToast("Please fill all the fields");
      } else {
        const formData = new FormData();

        formData.append("fullname", this.fullName);
        formData.append("email", this.email);
        formData.append("schools", this.jsonSelectedSchools);
        formData.append("gmat", this.GMATorGRE);
        formData.append("linkedinURL", this.linkedinURL);
        formData.append("cv", this.cv);

        axios
          .post(
            "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/mba/apply/",
            formData
          )
          .then(() => {
            this.showToast();
            this.fullName = "";
            this.email = "";
            this.cv = null;
            this.selectedSchools = [];
            this.GMATorGRE = null;
            this.linkedinURL = "";
          })
          .catch((error) => {
            this.showErrorToast(error.message);
          });
      }
    },
  },
};
</script>

<style>
/* Make the image height 100px and width auto  */

.auto-slider {
  height: 100px; /* Set the desired height of the slider */
}
.slider-container {
  display: flex;
  overflow: hidden;
}
.slider-track {
  display: flex;
  /* Add your styling for the slider track */
  /* animation: slideAnimation 50s linear infinite; */
  animation: slideAnimation 50s linear infinite;
}

.slider-image {
  height: 50px;
  width: auto;
}

.slider-item {
  padding-right: 40px;
  padding-left: 40px;
  flex-shrink: 0; /* Prevent the slider item from shrinking */
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideItemAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>
