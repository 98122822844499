<template>
  <div class="p-3">
    <div>
      <p class="bt-md text-left text-gray-800">Payment history</p>
    </div>

    <div class="tab-content" id="tabs-tabContent">
      <div>
        <!-- list of upcoming sessions -->

        <div v-if="noPayment == true" class="flex justify-center h-96">
          <div
            class="flex flex-col justify-center align-middle content-center items-center"
          >
            <img
              src="../../assets/noSessionsNew.svg"
              class="justify-center align-middle content-center items-center z-50"
              alt=""
            />
            <img
              src="../../assets/noSessionsNew2.svg"
              class="justify-center align-middle content-center items-center -mt-48 transform hover:rotate-45 transition-transform duration-500 ease-in-out"
              alt=""
            />
            <p class="text-center bt-book py-8">No paid sessions yet</p>

            <div class="flex justify-center">
              <router-link
                class="bg-custom-blue text-white px-2.5 py-2 font-semibold text-xl rounded-xl"
                to="/mentors"
              >
                Find a mentor
              </router-link>
            </div>
          </div>
        </div>

        <ul class="">
          <li
            v-for="paymentData in paymentData"
            :key="paymentData.id"
            class="flex py-2"
          >
            <div
              class="w-full flex flex-row rounded-2xl py-2 md:px-6 bg-gray-50 shadow-md"
            >
              <div class="py-2">
                <img
                  :src="paymentData.mentorImg"
                  alt=""
                  class="h-12 w-12 rounded-full mr-2"
                />
              </div>
              <div class="w-full p-2 space-y-2 h-100">
                <div class="flex items-stretch justify-between">
                  <div>
                    <p class="bt-bold text-left">Session</p>
                  </div>
                  <div>
                    <p class="bt-bold text-custom-blue">
                      ${{ paymentData.sessionPrice }}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between">
                  <div>
                    <p class="bt-placeholder">
                      With {{ paymentData.mentorFirstName }}
                      {{ paymentData.mentorLastName }}
                    </p>
                  </div>
                  <div>
                    <p class="bt-placeholder">{{ paymentData.paymentDate }}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      paymentData: "",
      noPayment: false,
    };
  },

  beforeMount() {
    this.getPaymentHistory();
  },

  methods: {
    getPaymentHistory() {
      axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/payment/mentee/" +
            this.$root.uid
        )
        .then((response) => {
          this.paymentData = response.data;
          console.log(this.paymentData);
          console.log(this.paymentData.mentorFirstName);
          // change the date format
          for (let i = 0; i < this.paymentData.length; i++) {
            let date = new Date(this.paymentData[i].paymentDate);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            let dt = date.getDate();

            if (dt < 10) {
              dt = "0" + dt;
            }
            if (month < 10) {
              month = "0" + month;
            }

            this.paymentData[i].paymentDate = year + "-" + month + "-" + dt;
          }

          if (this.paymentData.length == 0) {
            this.noPayment = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // async getMentorData(userID) {
    //   console.log(userID);
    //   axios
    //     .get(
    //       "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/expert/profile/" +
    //         userID +
    //         "/"
    //     )
    //     .then((response) => {
    //       this.candDatas = response.data;
    //       console.log(this.candDatas);
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
};
</script>

<style></style>
