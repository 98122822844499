<template>
  <center class="h-screen w-screen">
    <!-- <div class="z-50">
      <a href="/">go home</a>
    </div> -->
    <img src="../assets/Error.svg" alt="" />
  </center>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>
