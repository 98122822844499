const dataLayer = {
  pushPurchaseEvent: (
    transactionId,
    //affiliation,
    revenue,
    //tax,
    //shipping,
    items
  ) => {
    window.dataLayer.push({ ecommerce: null });
    console.log(window.dataLayer);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "purchase",
      ecommerce: {
        transaction_id: transactionId,
        value: revenue,
        //tax: tax,
        //shipping: shipping,
        currency: "USD",
        //coupon: "SUMMER_SALE",
        items: items,
      },
    });
  },
};

export default dataLayer;
