<template>
  <img
    :src="currentImage.src"
    :alt="currentImage.alt"
    class="w-full md:w-9/12 md:h-9/12"
  />
  <span class="space-x-8">
    <button
      @click="prevImage"
      class="slider-button left-button px-2 py-2 text-white bg-gray-500 rounded-full top-1/2 transform -translate-y-1/2 z-10"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        class="w-6 h-6"
      >
        <path
          fill-rule="evenodd"
          d="M7.72 12.53a.75.75 0 010-1.06l7.5-7.5a.75.75 0 111.06 1.06L9.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5z"
          clip-rule="evenodd"
        />
      </svg>
    </button>
    <button
      @click="nextImage"
      class="slider-button right-button px-2 py-2 text-white bg-gray-500 rounded-full top-1/2 transform -translate-y-1/2 right-0 z-10"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M8.25 4.5l7.5 7.5-7.5 7.5"
        />
      </svg>
    </button>
  </span>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,

      images: [
        {
          id: 1,
          src: require("../assets/mba_club/successStory1.jpg"),
          alt: "Slider Image 1",
        },
        {
          id: 2,
          src: require("../assets/mba_club/successStory2.jpg"),
          alt: "Slider Image 2",
        },
        {
          id: 3,
          src: require("../assets/mba_club/successStory3.jpg"),
          alt: "Slider Image 3",
        },
        {
          id: 4,
          src: require("../assets/mba_club/successStory4.jpg"),
          alt: "Slider Image 4",
        },
        {
          id: 5,
          src: require("../assets/mba_club/successStory5.jpg"),
          alt: "Slider Image 5",
        },
        {
          id: 6,
          src: require("../assets/mba_club/successStory6.jpg"),
          alt: "Slider Image 6",
        },
        // {
        //   id: 7,
        //   src: require("../assets/mba_club/successStory7.jpg"),
        //   alt: "Slider Image 7",
        // },
        {
          id: 7,
          src: require("../assets/mba_club/successStory8.jpg"),
          alt: "Slider Image 8",
        },
        {
          id: 8,
          src: require("../assets/mba_club/successStory9.jpg"),
          alt: "Slider Image 9",
        },
        {
          id: 9,
          src: require("../assets/mba_club/successStory10.jpg"),
          alt: "Slider Image 10",
        },
      ],
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentIndex];
    },
  },
  methods: {
    prevImage() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    nextImage() {
      if (this.currentIndex < this.images.length - 1) {
        this.currentIndex++;
      }
    },
  },
};
</script>

<style>
.slider-button {
  background-color: #d1d1d1;
}
</style>
