<template>
  <div>
    <h1 class="flex justify-center md:text-3xl p-4 font-bold">
      Search results
    </h1>
    <div class="pb-7 px-6 md:px-0">
      <SearchBar />
    </div>
    <TagsNew :searchTags="this.searchTags" />
    <div
      class="w-full flex flex-col justify-center py-12"
      v-if="notFound == true"
    >
      <p class="text-4xl text-center bt-medium -pb-12">
        Oops, nothing found 📡
      </p>
      <mentorRequest />
    </div>

    <ul
      v-if="notFound == false"
      class="flex md:flex-nowrap lg:flex-nowrap xl:flex-nowrap flex-wrap justify-center items-center pb-14 pt-4"
    >
      <li
        v-for="mentorData in mentorDatas"
        :key="mentorData.userID"
        :currentMentorId="mentorData.userID"
        class="rounded-3xl my-4 md:my-2 lg:my-8 xl:my-4 sm:mx-8 md:mx-12 lg:mx-12 h-fit w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/4"
      >
        <router-link
          :to="/mentor/ + mentorData.firstName + '-' + mentorData.lastName"
        >
          <div class="w-full flex justify-center items-center col-end-1">
            <div class="flex flex-col items-center">
              <!-- Image emplacement -->
              <div class="bg-white h-32 w-32 rounded-full relative -mb-24">
                <button class="z-50">
                  <img
                    v-bind:src="mentorData.profileImg"
                    alt=""
                    class="rounded-full"
                  />
                </button>
              </div>
              <!-- Content Card emplacement -->
              <div
                class="shadow-2xl rounded-2xl w-full md:w-282 xl:w-379 h-399 pb-8 px-8 relative pt-24"
              >
                <div>
                  <p class="text-center bt-smalltitle font-bold pb-3.5 pt-3">
                    {{ mentorData.firstName }} {{ mentorData.lastName }}
                  </p>

                  <div class="flex items-center justify-center pb-4">
                    <button
                      class="bg-black text-white hover:bg-white rounded-2xl text-center bt-book px-6 py-1.5"
                    >
                      {{ mentorData.price }}$ per session
                    </button>
                  </div>

                  <div
                    class="flex flex-row pb-3 items-center justify-center space-x-9"
                  >
                    <div class="flex flex-row items-start">
                      <img src="../assets/jobicon.svg" alt="" class="pr-2" />

                      <p class="bt-book truncate">
                        {{ mentorData.currentCompany }}
                      </p>
                    </div>
                    <div class="flex flex-row items-start">
                      <img
                        src="../assets/locationicon.svg"
                        alt=""
                        class="pr-2"
                      />

                      <p class="bt-book truncate">{{ mentorData.location }}</p>
                    </div>
                  </div>

                  <p class="text-center bt-smalltext pb-6 h-24">
                    {{ mentorData.services }}
                  </p>
                  <div class="flex justify-center">
                    <button
                      @click="mentorDetails(mentorData.userID)"
                      class="h-11 w-32 border-2 rounded-full border-custom-blue text-custom-blue hover:bg-custom-blue hover:text-white duration-300 bt-book px-2 py-1.5"
                    >
                      See More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
  <Footer />
</template>

<script>
import axios from "axios";
import Footer from "../components/Footer.vue";
import SearchBar from "../components/SearchBar.vue";
import mentorRequest from "../components/mentorRequest.vue";

export default {
  name: "MentorPage",
  data() {
    return {
      notFound: false,
      email: "",
      maxChar: 50,
      mentorDatas: [],
      getMentorDetails: false,
      search: "",
      searchTags: ["Azerbaijan", "Big4", "Software", "Finance"],
    };
  },
  mounted() {
    this.searchMentor();
  },
  methods: {
    async mentorDetails(mentorId) {
      //console.log(mentorId);
      await axios
        .get(
          "https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/expert/profile/" +
            mentorId +
            "/"
        )
        .then((response) => {
          this.mentorDatas = response.data;
          console.log(this.mentorDatas);
          console.log(mentorId);
          this.fullname =
            this.mentorDatas.firstName + "-" + this.mentorDatas.lastName;
          // router push to mentor details with fullname as :str
          localStorage.setItem("fullname", this.fullname);
          console.log(localStorage.getItem("fullname"));
          this.$router.push({
            path: "/mentor/" + this.fullname,
          });
        })
        .catch((error) => {
          console.log(error);
        });
      // this.goToMentorDetails(this.fullname);
      // this.$router.push({
      //   path: "/mentorDetails/" + this.fullname,
      // });
      // console.log(this.fullname);
      console.log(localStorage.getItem("fullname"));
      localStorage.setItem("mentorId", mentorId);
    },

    searchMentor() {
      this.currentURL = window.location.href;
      console.log(this.currentURL);
      let counter = 0;
      let i = 0;
      while (counter < 4) {
        i++;
        if (this.currentURL[i] === "/") {
          counter++;
        }
      }
      let str = "";
      if (this.currentURL[this.currentURL.length] === "/")
        str = this.currentURL.slice(i + 1, this.currentURL.length - 1);
      else str = this.currentURL.slice(i + 1, this.currentURL.length);

      axios
        .get(
          `https://2d13ac092947-hirelamp-bbcf628a86ebae0f2646300d98508d5.co/search/` +
            str
        )
        .then((res) => {
          let key = "";
          let finalarr1 = [];
          console.log(res.data);
          for (key in res.data) {
            if (Object.hasOwnProperty.call(res.data, key)) {
              const element = res.data[key];
              if (element.length > 0) {
                finalarr1 = finalarr1.concat(element);
              }
            }
          }
          // let finalarr2;
          this.mentorDatas = finalarr1;
          console.log(this.mentorDatas);
          for (var i = 0; i < this.mentorDatas.length; i++) {
            if (this.mentorDatas[i].services.length > 120) {
              this.mentorDatas[i].services = this.mentorDatas[i].services.slice(
                0,
                135
              );
              this.mentorDatas[i].services += "...";
            }
          }
          if (this.mentorDatas.length === 0) {
            this.notFound = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },

  components: {
    Footer,
    SearchBar,
    // DropDownMenu,
    mentorRequest,
  },
};
</script>

<style></style>
